/**
 * @format
 * @flow strict-local
 */

import React from 'react';
import { Drawer } from '@appComponents/navigation';

import Settings from './Settings/Settings';
import Help from './Help/Help';
import Quickstart from './Help/Quickstart';
import Manual from './Help/Manual';
import Faq from './Help/Faq';
import BestPractices from './Help/BestPractices';
import TermsAndDefinitions from './Help/TermsAndDefinitions';

import { useMyData } from '@appUtils/api';
import { groupOptions, useDrawerContent, useScreenOptions } from '.';

export default function SubscriptionStack() {
  const [user, loading] = useMyData();
  const { drawerWidth, drawerContent } = useDrawerContent();
  const options = useScreenOptions({ drawerWidth, user, loading });

  return (
    <Drawer.Navigator
      drawerContent={drawerContent}
      defaultStatus="open"
      backBehavior="history"
      screenOptions={options}
      initialRouteName="Settings">
      <Drawer.Group screenOptions={groupOptions}>
        <Drawer.Screen name="Settings" component={Settings} />
        <Drawer.Screen name="Help" component={Help} />
        <Drawer.Screen name="Help Quickstart" component={Quickstart} />
        <Drawer.Screen
          name="Help Terms and Definitions"
          component={TermsAndDefinitions}
        />
        <Drawer.Screen name="FlightApp! User Manual" component={Manual} />
        <Drawer.Screen name="Help FAQ" component={Faq} />
        <Drawer.Screen name="Help Best-Practices" component={BestPractices} />
      </Drawer.Group>
    </Drawer.Navigator>
  );
}
