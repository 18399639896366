import React from 'react';

import { ScreenLayout, MainSurface } from '@appComponents/ScreenLayout';
import UserDetails from '@webComponents/UserDetails';
import { UserRole } from '@appUtils/tripConverter';
import { GuestPassengers } from '@webComponents/UserDetails/GuestPassengers';

const OwnerDetails = ({ route }) => {
  const userId = route.params.userId;

  return (
    <ScreenLayout alignItems="stretch">
      <MainSurface>
        <UserDetails userId={userId} role={UserRole.OWNER}>
          <GuestPassengers ownerId={userId} />
        </UserDetails>
      </MainSurface>
    </ScreenLayout>
  );
};

export default OwnerDetails;
