/**
 * @file
 * A component displaying totals due from Owners based on Expenses and Adjustments
 *
 * @format
 * @flow strict-local
 */
import React from 'react';
import AccountingUsers from '@webComponents/Accounting/AccountingUsers';
import { AccountingTab } from '.';

const AccountingOwners = ({ userTotals, company }) => {
  const userDueText = 'Due From Client';
  return (
    <AccountingUsers
      userTotals={userTotals}
      userDueText={userDueText}
      tab={AccountingTab.CLIENTS}
      company={company}
    />
  );
};

export default AccountingOwners;
