/**
 * @file
 * A component displaying totals due per trip
 *
 * @format
 * @flow strict-local
 */
import React, { useMemo, useState } from 'react';
import _ from 'lodash';

import { Box } from '@appComponents/ScreenLayout';
import TripsTable from '@webComponents/TripsTable';

const getTripTotals = ({ ownerTotals, pilotTotals }) => {
  const tripTotals = {};
  Object.keys(ownerTotals)?.map(key => {
    const { total, userName, ...trips } = ownerTotals[key];
    Object.keys(trips)?.map(k => {
      const trip = trips[k];
      if (!tripTotals[trip.path]) {
        tripTotals[trip.path] = { ownerDue: trip.total };
      }
    });
  });

  Object.keys(pilotTotals)?.map(key => {
    const { total, userName, ...trips } = pilotTotals[key];
    Object.keys(trips)?.map(k => {
      const trip = trips[k];
      if (tripTotals[trip.path]?.pilotDue) {
        tripTotals[trip.path].pilotDue += trip.total;
      } else {
        tripTotals[trip.path].pilotDue = trip.total;
      }
    });
  });

  return tripTotals;
};

const AccountingTrips = ({ trips, ownerTotals, pilotTotals }) => {
  const [sort, setSort] = useState(INITIAL_SORT);
  const tripTotals = getTripTotals({ ownerTotals, pilotTotals });
  const dueTrips = trips?.map(t => {
    const totals = tripTotals[t.path];
    t.ownerDue = totals?.ownerDue ?? 0;
    t.pilotDue = totals?.pilotDue ?? 0;
    t.netDue = t.ownerDue - t.pilotDue ?? 0;
    return t;
  });
  const sortedTrips = useMemo(() => {
    return _.orderBy(dueTrips, [getSortField(sort.title)], [sort.dir]);
  }, [dueTrips, sort]);

  return (
    <Box pb={1} ph={0.5}>
      <TripsTable
        data={sortedTrips}
        initialSort={sort}
        onSortChange={setSort}
        accounting
      />
    </Box>
  );
};

const getSortField = sortKey => {
  switch (sortKey) {
    case 'ID':
      return 'identifier';
    case 'State':
      return '_state';
    case 'Name':
      return 'customName';
    case 'Tail #':
      return 'aircraft.tailNumber';
    case 'Legs':
      return 'departingFrom';
    case 'Departure':
      return 'dateDeparting';
    case 'Due from Client':
      return 'ownerDue';
    case 'Due to Pilots':
      return 'pilotDue';
    case 'Net Due':
    default:
      return 'netDue';
  }
};

const INITIAL_SORT = { dir: 'desc', title: 'Net Due' };

export default AccountingTrips;
