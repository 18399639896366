/**
 * @file
 * A component for creating or editing a DRAFT trip
 *
 * @format
 * @flow strict-local
 */

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ScrollView } from 'react-native';
import styled from '@emotion/native';
import { TextInput } from 'react-native-paper';
import _ from 'lodash';
import { useAsyncCallback } from 'react-async-hook';

import { CompositeScreenProps } from '@react-navigation/native';

import Button from '@appComponents/Button';
import {
  Box,
  Header,
  MainSurface,
  ScreenLayout,
  ScreenLoader,
} from '@appComponents/ScreenLayout';
import TextField from '@appComponents/TextField';
import Text from '@appComponents/Text';
import { useTripAircraft } from '@appUtils/aircraft';
import { useTrip, useTripPilotOptions } from '@appUtils/manager';
import { userToPassengerData } from '@appUtils/passengers';
import * as Phone from '@appUtils/phone';
import { OwnerState, TripState, TripTab } from '@appUtils/tripConverter';
import Steps from '@webComponents/Steps';
import SelectOwner from '@webComponents/SelectOwner';
import OwnerStatus from '@webComponents/OwnerStatus';
import TripLegs from './TripLegs';
import Aircraft from './Aircraft';
import Pilots from './Pilots';
import Passengers from './Passengers';
import TripName from './TripName';
import TripNotes from './TripNotes';
import useExitConfirmation from './useExitTripBuilderConfirmation';
import TripMessages, { MessagesButton } from '@webComponents/TripMessages';
import { useTripConversation } from '@appUtils/messages';
import { useTheme } from '@appComponents/theme';
import { useTripDocumentsDialog } from '@webComponents/TripDocuments';

const TripBuilder = ({ route, navigation }: CompositeScreenProps) => {
  const path = route.params?.documentPath;
  const builder = useDraftTrip(path);
  const { confirmationNode, screenVisible } = useExitConfirmation(
    builder,
    navigation,
  );

  return (
    <>
      {screenVisible && (
        <ScreenLayout alignItems="stretch">
          <TripBuilderHeader builder={builder} />
          <TripBuilderContent builder={builder} navigation={navigation} />
        </ScreenLayout>
      )}
      {confirmationNode}
    </>
  );
};

const TripBuilderHeader = ({ builder }) => {
  const theme = useTheme();
  const selectRef = useRef(null);

  return (
    <Header>
      <SelectOwner
        key={builder.trip?.owner}
        owner={builder.trip?.owner}
        isClearable={false}
        disabled={
          builder.trip?.createdBy ===
          _.get(builder, ['trip', 'owner', 'id'], '')
        }
        onChange={builder.changeOwner}
        style={styles.ownerField}
        controlStyle={{
          height: styles.ownerField.height,
          backgroundColor: theme.colors.background,
          color: theme.colors.text,
          borderColor: theme.colors.fieldBorder,
        }}
        innerRef={selectRef}
        isRequired
      />
      {builder.trip?.owner && (
        <>
          <TextField
            label="Contact Client"
            editable={false}
            value={Phone.parse(builder.trip.owner.phoneNumber).formatNational()}
            wrapStyle={styles.phoneField}
            left={<TextInput.Icon name="phone" />}
          />
          <OwnerStatus state={builder.trip.owner.state} />
        </>
      )}
    </Header>
  );
};

const TripBuilderContent = ({ builder, navigation }) => {
  const [messagesVisible, setMessagesVisible] = useState(false);
  const messageIcon = messagesVisible ? 'arrow-drop-up' : 'arrow-drop-down';
  const trip = builder.trip;
  const saveDraft = useAsyncCallback(builder.save);
  const conversation = useTripConversation(trip?.id);
  const unreadMessages = conversation?.managementUnreadMessages;
  const { dialogNode: documentsDialogNode, openDialog: openDocumentsDialog } =
    useTripDocumentsDialog({
      saveTrip: builder.save,
      updateTrip: builder.update,
      tripDocuments: trip?.documents,
      tripId: trip?.id,
    });

  const done = useCallback(() => {
    const tab = _.get(
      {
        [TripState.OWNER_REQUEST]: TripTab.REQUESTED,
        [TripState.DRAFT]: TripTab.DRAFT,
        [TripState.UPCOMING]: TripTab.UPCOMING,
        [TripState.ACTIVE]: TripTab.ACTIVE,
        [TripState.ENDED]: TripTab.ENDED,
        [TripState.CANCELLED]: TripTab.ENDED,
      },
      trip.state,
      TripTab.DRAFT,
    );

    navigation.navigate('Trips', { tab });
  }, [navigation, trip.state]);

  return (
    <BuildSurface>
      {documentsDialogNode}
      <Box dir="row" mh={1.5} height={42} jc="center">
        <TripName value={trip.customName} onChange={builder.changeCustomName} />
        <Box flex={1} />
        <TripNotes
          mode="outlined"
          mr={0.5}
          key={trip.notes}
          defaultValue={trip.notes}
          onSave={builder.changeNotes}
          style={styles.button}
        />
        <MessagesButton
          mr={0.5}
          messageIcon={messageIcon}
          trip={trip}
          messagesVisible={messagesVisible}
          setMessagesVisible={setMessagesVisible}
          unreadMessages={unreadMessages}
          builder={true}
        />
        <Button
          style={styles.button}
          onPress={openDocumentsDialog}
          disabled={!trip.id}>
          Trip Documents
        </Button>
      </Box>
      {builder.loading && <ScreenLoader />}

      {builder.error && <Text>{builder.error.message}</Text>}

      {!builder.loading && !builder.error && (
        <StepContent>
          <BuildSteps
            steps={builder.steps}
            onStepPress={builder.setActiveIndex}
            activeIndex={builder.activeIndex}
          />

          <ScrollView contentContainerStyle={styles.scrollContent}>
            {builder.currentStep.render()}
          </ScrollView>
        </StepContent>
      )}
      <RightAlignedMessageBox>
        <TripMessages
          visible={messagesVisible}
          onClose={() => setMessagesVisible(false)}
          trip={trip}
        />
      </RightAlignedMessageBox>
      <BuildFooter>
        <Button
          loading={saveDraft.loading}
          ml="auto"
          mr={1}
          color="accent"
          mode="outlined"
          disabled={saveDraft.loading || !builder.canSave}
          onPress={() => {
            setMessagesVisible(false);
            saveDraft.execute();
          }}>
          Save And Send
        </Button>
      </BuildFooter>
    </BuildSurface>
  );
};

const getPassengerReqsText = ({ owner, aircraft }) => {
  let text;
  if (!owner && !aircraft) {
    text =
      'Please select a client and an aircraft before assigning passengers.';
  } else if (owner && !aircraft) {
    text = 'Please select an aircraft before assigning passengers.';
  } else if (aircraft && !owner) {
    // This shouldn't be possible, but just in case
    text = 'Please select a client before assigning passengers.';
  }
  return text;
};

const useDraftTrip = documentPath => {
  const { update, data: trip, save, reset, ...builder } = useTrip(documentPath);
  const aircraftOptions = useTripAircraft(trip);
  const tripAircraft = aircraftOptions.list.find(
    o => o.tailNumber === trip.aircraft?.tailNumber,
  );
  const passengerReqsText = getPassengerReqsText({
    owner: trip?.owner,
    aircraft: trip?.aircraft,
  });

  const pilotOptions = useTripPilotOptions(trip, tripAircraft?.pilots);

  const changeLegs = useCallback(legs => update({ legs }), [update]);
  const changePilots = useCallback(pilots => update({ pilots }), [update]);
  const changeNotes = useCallback(notes => update({ notes }), [update]);

  const selectAircraft = useCallback(
    aircraft => update({ aircraft }),
    [update],
  );

  const changeOwner = useCallback(
    async owner => {
      const ownerData = {
        documents: [],
        ...owner,
        state: OwnerState.MANAGER_DRAFT,
      };
      update({
        owner: ownerData,
        aircraft: null,
        pilots: [],
      });
      // Remove passengers associated with the previous owner and add the new owner as a passenger
      changeLegs(
        trip.legs.map(leg => {
          return {
            ...leg,
            passengers: [
              ...leg.passengers.filter(p => !p.ownerAssociated),
              userToPassengerData({ user: owner }),
            ],
          };
        }),
      );
    },
    [changeLegs, trip.legs, update],
  );

  const changeCustomName = useCallback(
    customName => update({ customName }),
    [update],
  );

  const dispatchTrip = useCallback(() => {
    update({ state: TripState.UPCOMING });
    return save();
  }, [save, update]);

  const steps = useMemo(
    () => [
      {
        name: 'Legs',
        isComplete: Boolean(
          trip.owner && _.head(trip.legs).from && _.head(trip.legs).to,
        ),
        render: () => <TripLegs legs={trip.legs} onChange={changeLegs} />,
      },
      {
        name: 'Aircraft',
        isComplete: Boolean(trip.aircraft?.tailNumber),
        hasWarning: aircraftOptions.unavailable.some(
          option => option.tailNumber === trip.aircraft?.tailNumber,
        ),
        render: () => (
          <Aircraft
            onSelect={selectAircraft}
            selected={trip.aircraft}
            aircraftOptions={aircraftOptions}
            owner={trip.owner}
            legs={trip.legs}
          />
        ),
      },
      {
        name: 'Passengers',
        isComplete: _.size(trip.legs?.flatMap(leg => leg.passengers)) > 0,
        render: () =>
          passengerReqsText ? (
            <Text align="center">{passengerReqsText}</Text>
          ) : (
            <Passengers
              trip={trip}
              changeLegs={changeLegs}
              legs={trip.legs}
              owner={trip?.owner}
              aircraft={trip?.aircraft}
            />
          ),
      },
      {
        name: 'Pilots',
        isComplete: _.size(trip.pilots) > 0,
        render: () => (
          <Pilots
            pilots={trip.pilots}
            pilotOptions={pilotOptions}
            changePilots={changePilots}
            aircraft={tripAircraft}
          />
        ),
      },
    ],
    [
      aircraftOptions,
      changeLegs,
      changePilots,
      passengerReqsText,
      pilotOptions,
      selectAircraft,
      trip,
      tripAircraft,
    ],
  );

  const [activeIndex, setActiveIndex] = useState(0);

  const goToNextStep = useCallback(
    () => setActiveIndex(current => current + 1),
    [],
  );

  const goToPrevStep = useCallback(
    () => setActiveIndex(current => current - 1),
    [],
  );

  const init = useCallback(() => {
    reset();
    setActiveIndex(0);
  }, [reset]);

  const canSave = _.head(steps).isComplete && trip.customName;

  return {
    steps,
    activeIndex,
    setActiveIndex,
    goToPrevStep,
    goToNextStep,
    dispatchTrip,
    currentStep: steps[activeIndex],
    trip,
    changeOwner,
    changeCustomName,
    changeNotes,
    canSave,
    save,
    init,
    update,
    ...builder,
  };
};

const BuildSurface = styled(MainSurface)`
  padding-top: ${({ theme }) => theme.layout.space(1.25).toString()}px;
`;

const BuildSteps = styled(Steps)(({ theme }) => ({
  width: '85%',
  minWidth: 880,
  marginTop: theme.layout.space(2),
  alignSelf: 'center',
  marginBottom: theme.layout.space(2.5),
}));

const StepContent = styled.View(({ theme }) => ({
  flex: 1,
}));

const BuildFooter = styled.View`
  flex-direction: row;
  justify-content: space-between;
  border-top-width: 1px;
  border-top-color: ${({ theme }) => theme.colors.border};
  margin-top: auto;
  padding: ${({ theme }) =>
    `${theme.layout.space(1)}px ${theme.layout.space(1.5)}px`};
`;

const styles = {
  phoneField: {
    marginLeft: 16,
    marginBottom: 8,
  },
  ownerField: {
    height: 56,
    minWidth: 280,
  },
  button: {
    height: '100%',
    justifyContent: 'center',
  },
  scrollContent: {
    flex: 1,
    width: '86%',
    minWidth: 900,
    marginHorizontal: 'auto',
  },
};

const RightAlignedMessageBox = styled.View`
  position: absolute;
  right: 0;
  bottom: 90px;
  z-index: 1;
`;

export default TripBuilder;
