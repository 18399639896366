import React from 'react';

import Icon from '@appComponents/theme/Icon';
import { HelpBase, HelpText, HelpHeader } from './Help';
import { ExternalLink } from '../../common/Terms';

const Quickstart = () => (
  <HelpBase>
    <HelpHeader>Step 1: User Account Setup</HelpHeader>
    <ol>
      <li>
        <HelpText>Go to </HelpText>
        <ExternalLink to="https://managers.flightapp.io" />
        <HelpText> and create your FlightApp! <HelpText bold>Management</HelpText> account.</HelpText>
        <ol type="a">
          <li><HelpText>Make note of your Company ID, top right corner once logged in.</HelpText></li>
        </ol>
      </li>
      <li><HelpText>Share the Company ID with client aircraft Clients, Pilots, and Passengers (CPPs).</HelpText></li>
      <li><HelpText>Instruct CPPs to download FlightApp! from the Apple App Store </HelpText></li>
      <li><HelpText>CPPs need to set up their free accounts in the mobile app and connect to the Management Company using the Company ID.</HelpText></li>
      <li><HelpText>Management Company must approve CPP account requests via the Manger app.</HelpText></li>
      <li>
        <HelpText>To add additional Managers, go to </HelpText>
        <ExternalLink to="https://managers.flightapp.io" />
        <HelpText> and select "Join Company." (need Company ID)</HelpText>
      </li>
    </ol>

    <HelpHeader>Step 2: Management Company Setup</HelpHeader>
    <ol>
      <li><HelpText>Add an Aircraft in the Aircraft tab</HelpText></li>
      <li><HelpText>Assign at least one Client to each aircraft.</HelpText></li>
      <li><HelpText>Assign at least one Pilot to each aircraft.</HelpText></li>
      <li><HelpText>Optional - Set up Accounting for each aircraft.</HelpText></li>
      <ol>
        <li><HelpText>Customize expense payment accounts for each aircraft to simplify trip expense reconciliation. </HelpText></li>
        <li><HelpText>Set up payment methods for each Client and Pilot in the Accounting tab under Setup.  </HelpText></li>
        <li><HelpText>Set up Payments. This allows the Management Company to pay Pilots and receive payments from Clients for trip expenses with a single click.</HelpText></li>
      </ol>
      <li><HelpText><HelpText bold>Note:</HelpText> At least one aircraft, one Client, and one assigned Pilot are required to create trips.
      </HelpText></li>
    </ol>


    <HelpHeader>Step 3: Creating Trips with Trip Builder</HelpHeader>
    <ul>
      <li><HelpText>Trips can be built in:
      </HelpText></li>
      <ul>
        <li><HelpText>The Management Company web app
        </HelpText></li>
        <li><HelpText>The Client mobile app
        </HelpText></li>
        <li><HelpText>The Pilot mobile app
        </HelpText></li>
      </ul>
    </ul>


    <HelpHeader>Step 4: Managing Trips</HelpHeader>
    <ol>
      <li><HelpText>The Manager moves the trip’s status through the following stages:
      </HelpText></li>
      <ul>
        <li><HelpText>Draft → Upcoming → Active → Completed → Archived </HelpText></li>
        <li><HelpText>This status update applies to all CPP trip participants.
        </HelpText></li>
      </ul>
      <li><HelpText bold>Messaging:</HelpText></li>
      <ul>
        <li><HelpText>Each trip has a dedicated messaging channel. All CPP trip participants can read and write messages.</HelpText></li>
      </ul>
      <li><HelpText bold>Trip Notes:
      </HelpText></li>
      <ul>
        <li><HelpText>Typically used by Clients for special trip requests. All CPP participants can view and contribute to the Trip Notes.
        </HelpText></li>
      </ul>
      <li><HelpText bold>Recording Aircraft Data:
      </HelpText></li>
      <ul>
        <li><HelpText>Pilots can log aircraft metering data.
        </HelpText></li>
        <li><HelpText>Add maintenance squawks with images.
        </HelpText></li>
        <li><HelpText>Complete post-flight checklists.
        </HelpText></li>
      </ul>
      <li><HelpText bold>Recording Trip Expenses:
      </HelpText></li>
      <ul>
        <li><HelpText>Pilots can log and document trip expenses in their app.
        </HelpText></li>
        <li><HelpText>Managers can view, edit, and add expenses through their app.
        </HelpText></li>
      </ul>
      <li><HelpText bold>Processing Trip Expenses:</HelpText></li>
      <ol>
        <li><HelpText>Pilots submit all trip expenses by pressing "Expenses Completed."
        </HelpText></li>
        <li><HelpText>Managers can modify any submitted expenses.
        </HelpText></li>
        <li><HelpText>Managers can add additional expenses to a trip.
        </HelpText></li>
        <li><HelpText>Once all expenses are documented, Managers submit the charges for the Client's approval.
        </HelpText></li>
        <li><HelpText>Upon Client approval, their payment to the Management Company is processed instantly (if payments are set up).</HelpText></li>
        <li><HelpText>Managers can process expense reimbursements to Pilots instantly (if payments are set up).
        </HelpText></li>
      </ol>
    </ol>

    <HelpText bold>Notes:</HelpText>
    <ul>
      <li><HelpText>Management company uses the web app & CPPs use the mobile app</HelpText></li>
      <li><HelpText>Automated payment processing requires that Clients, Managers, and Pilots have their payment profiles set up. See “Settings” tab in apps. </HelpText></li>
      <li><HelpText>Any expense can be flagged by Pilots, Clients, or Managers, which enables a comment modal for discussion.</HelpText></li>
      <li><HelpText>There are two types of passengers:</HelpText></li>
      <ul>
        <li><HelpText><HelpText bold>App Passengers</HelpText> – Passengers with a FlightApp! mobile account.
        </HelpText></li>
        <li>
          <HelpText>
            <HelpText bold>Guest Passengers</HelpText> – Passengers not using FlightApp!, indicated by the </HelpText>
          <Icon style={{ display: 'inline-block' }} name="guest-passenger-indicator" />
        </li>
        <li><HelpText>Guest Passengers cannot be converted to App Passengers. A new mobile account must be created if they wish to become App Passengers.</HelpText></li>
      </ul>
      <li><HelpText>Clients cannot directly assign Pilots to a trip, but they can request specific Pilots through Trip Notes.
      </HelpText></li>
      <li><HelpText>The status of a maintenance squawk is set by the Manager
      </HelpText></li>
    </ul>
  </HelpBase>
);

export default Quickstart;
