/**
 * @file
 * Components for adjusting expenses related to a trip
 *
 * @format
 * @flow strict-local
 */
import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { NumericFormat } from 'react-number-format';
import styled from '@emotion/native';
import { useForm, useFormState } from 'react-hook-form';
import { Box, Spacer } from '@appComponents/ScreenLayout';
import {
  FormatDollars,
  parenthesizedNumberToNegative,
} from '@appUtils/accounting';
import { FitToContentButton } from '@appComponents/Button';
import {
  NumberFormField,
  TextFormField,
} from '@appComponents/forms/FormFields';
import Tabs from '@appComponents/Tabs';
import _ from 'lodash';
import {
  ExpensesCompletePill,
  HeaderText,
  TextWrapper,
} from '@webComponents/Accounting/General';
import { UserRole } from '@appUtils/tripConverter';
import { Icon } from '@appComponents/theme';

const Adjustments = ({
  expenseAdjustments,
  dueToLabel,
  dueToName,
  control,
  theme,
  remainingBalance,
  prefix,
}) => {
  const latestAdjustment = {
    amountDue: remainingBalance,
    dueToRole: '',
    dueToName: dueToName,
    adjustment: '',
    note: '',
    dateCreated: new Date(),
  };

  return (
    <Column w={'100%'}>
      <Row ph={theme.layout.space(0.5)}>
        <Column w={adjustmentWidths.dueTo} ai={'center'}>
          <HeaderText size={'small'}>DUE TO {dueToLabel}</HeaderText>
        </Column>
        <Column w={adjustmentWidths.adjustment} ai={'center'}>
          <HeaderText size={'small'}>ADJUSTMENT</HeaderText>
        </Column>
        <Column w={adjustmentWidths.note} ai={'start'}>
          <HeaderText size={'small'}>NOTE</HeaderText>
        </Column>
      </Row>
      <ScrollWrapper>
        {expenseAdjustments.map(adjustment => (
          <AdjustmentRow
            adjustment={adjustment}
            control={control}
            theme={theme}
          />
        ))}
        <AdjustmentRow
          adjustment={latestAdjustment}
          control={control}
          theme={theme}
          latest
          prefix={prefix}
        />
      </ScrollWrapper>
    </Column>
  );
};

const adjustmentWidths = {
  dueTo: '30%',
  adjustment: '30%',
  note: '40%',
};

const BorderedBox = styled(View)(({ theme }) => ({
  flexDirection: 'column',
  width: '100%',
  borderRadius: theme.roundness,
  borderColor: theme.colors.text,
  borderWidth: 1,
  padding: theme.layout.space(1),
  height: theme.layout.space(36),
}));

const Column = styled(View)(({ w, ml, mr, ai, pr }) => ({
  flexDirection: 'column',
  width: w ?? '50%',
  justifyContent: 'space-between',
  alignItems: ai ?? undefined,
  marginLeft: ml ?? 0,
  marginRight: mr ?? 0,
  paddingRight: pr ?? 0,
}));

const Row = styled(View)(({ h, c, mt, ph, pv, theme }) => ({
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: h ?? undefined,
  backgroundColor: c ?? undefined,
  marginTop: mt ?? undefined,
  paddingHorizontal: ph ?? undefined,
  paddingVertical: pv ?? undefined,
  borderRadius: theme.roundness,
}));

const adjustmentFieldStyle = theme => ({
  height: theme.layout.space(2),
  width: '100%',
  marginTop: theme.layout.space(0.75),
  textAlign: 'right',
});

const noteFieldStyle = theme => ({
  height: theme.layout.space(2),
  width: '100%',
  marginTop: theme.layout.space(0.75),
});

const AdjustmentRow = ({ adjustment, control, theme, latest, prefix }) => {
  return (
    <Row
      c={theme.colors.background}
      mt={theme.layout.space(0.25)}
      ph={theme.layout.space(0.5)}
      pv={latest ? undefined : theme.layout.space(0.67)}>
      <Column w={adjustmentWidths.dueTo} pr="5%">
        <FormatDollars
          dollars={
            adjustment?.amountDue < 0
              ? adjustment?.amountDue * -1
              : adjustment?.amountDue
          }
          prefix={adjustment?.amountDue < 0 ? '(' : ''}
          suffix={adjustment?.amountDue < 0 ? ')' : ''}
          renderText={value => (
            <TextWrapper aSelf={'flex-end'}>{value}</TextWrapper>
          )}
        />
      </Column>
      <Column w={adjustmentWidths.adjustment} pr="5%">
        {latest ? (
          <NumberFormField
            control={control}
            name={`${prefix}Adjustment`}
            style={adjustmentFieldStyle(theme)}
          />
        ) : (
          <NumericFormat
            value={
              adjustment?.adjustment < 0
                ? -1 * adjustment?.adjustment
                : adjustment?.adjustment
            }
            thousandSeparator={true}
            prefix={adjustment?.adjustment < 0 ? '(' : ''}
            suffix={adjustment?.adjustment < 0 ? ')' : ''}
            displayType="text"
            renderText={value => (
              <TextWrapper aSelf="flex-end">{value}</TextWrapper>
            )}
          />
        )}
      </Column>
      <Column w={adjustmentWidths.note}>
        {latest ? (
          <TextFormField
            control={control}
            name={`${prefix}Note`}
            style={noteFieldStyle(theme)}
          />
        ) : (
          <TextWrapper>
            {adjustment?.note === '' ? '-' : adjustment.note}
          </TextWrapper>
        )}
      </Column>
    </Row>
  );
};

const PilotTabs = ({ activeTab, onChange, pilotRoutes }) => {
  const [routes] = useState(pilotRoutes);
  const handleChange = useCallback(
    ({ route }) => onChange(route.key),
    [onChange],
  );

  return (
    <Row>
      <Tabs
        tabIndex={routes.findIndex(tab => tab.key === activeTab)}
        onTabPress={handleChange}
        routes={routes}
        tabBarInline
      />
    </Row>
  );
};

const useSaveAdjustmentCallback = ({
  existingAdjustments,
  newAdjustment,
  prefix,
  save,
  update,
}) =>
  useCallback(
    payload => {
      const expenseAdjustments = _.cloneDeep(existingAdjustments);
      switch (prefix) {
        case 'pic':
          newAdjustment.adjustment = Number(
            parenthesizedNumberToNegative(payload.picAdjustment),
          );
          newAdjustment.note = payload.picNote;
          break;
        case 'sic':
          newAdjustment.adjustment = Number(
            parenthesizedNumberToNegative(payload.sicAdjustment),
          );
          newAdjustment.note = payload.sicNote;
          break;
        case 'owner':
          newAdjustment.adjustment = Number(
            parenthesizedNumberToNegative(payload.ownerAdjustment),
          );
          newAdjustment.note = payload.ownerNote;
          break;
      }
      expenseAdjustments.push(newAdjustment);
      update({ expenseAdjustments });
      save();
    },
    [existingAdjustments, newAdjustment, prefix, save, update],
  );

const picDefaults = {
  picAdjustment: '',
  picNote: '',
};

const sicDefaults = {
  sicAdjustment: '',
  sicNote: '',
};

export const AccountsPayable = ({
  theme,
  picName,
  sicName,
  totalDueToPilots,
  totalDueToPic,
  totalDueToSic,
  existingAdjustments,
  picAdjustments,
  totalPicAdjustments,
  sicAdjustments,
  totalSicAdjustments,
  picExpensesComplete,
  sicExpensesComplete,
  update,
  save,
  processPaymentPic,
  processPaymentSic,
  picHasStripeAccount,
  sicHasStripeAccount,
  expensesApproved,
}) => {
  const picRemaining = totalDueToPic + totalPicAdjustments;
  const sicRemaining = totalDueToSic + totalSicAdjustments;
  const [activeTab, setActiveTab] = useState('pic');
  const pilotRoutes = [
    { key: 'pic', title: _.isEmpty(picName) ? 'Pilot in Command' : picName },
    { key: 'sic', title: _.isEmpty(sicName) ? 'Second in Command' : sicName },
  ];
  const {
    control: picControl,
    handleSubmit: picHandleSubmit,
    reset: picReset,
    watch: picWatch,
  } = useForm({
    defaultValues: picDefaults,
  });
  const { isSubmitSuccessful: picIsSubmitSuccessful } = useFormState({
    control: picControl,
  });
  const { picAdjustment, picNote } = picWatch();

  const {
    control: sicControl,
    handleSubmit: sicHandleSubmit,
    reset: sicReset,
    watch: sicWatch,
  } = useForm({
    defaultValues: sicDefaults,
  });
  const { isSubmitSuccessful: sicIsSubmitSuccessful } = useFormState({
    control: sicControl,
  });
  const { sicAdjustment, sicNote } = sicWatch();

  useEffect(() => {
    if (picIsSubmitSuccessful) {
      picReset(picDefaults);
    }
  }, [picIsSubmitSuccessful, picReset]);

  useEffect(() => {
    if (sicIsSubmitSuccessful) {
      sicReset(sicDefaults);
    }
  }, [sicIsSubmitSuccessful, sicReset]);

  const newPicAdjustment = {
    amountDue: picRemaining,
    dueToRole: UserRole.PILOT,
    dueToName: picName,
    dateCreated: new Date(),
  };

  const newSicAdjustment = {
    amountDue: sicRemaining,
    dueToRole: UserRole.PILOT,
    dueToName: sicName,
    dateCreated: new Date(),
  };

  const savePicAdjustment = picHandleSubmit(
    useSaveAdjustmentCallback({
      existingAdjustments,
      newAdjustment: newPicAdjustment,
      prefix: 'pic',
      update,
      save,
    }),
  );

  const saveSicAdjustment = sicHandleSubmit(
    useSaveAdjustmentCallback({
      existingAdjustments,
      newAdjustment: newSicAdjustment,
      prefix: 'sic',
      update,
      save,
    }),
  );

  const [sendingPicPayment, setSendingPicPayment] = useState(false);
  const [sendingSicPayment, setSendingSicPayment] = useState(false);

  return (
    <Column mr={theme.layout.space(1)}>
      <HeaderText size={'medium'}>ACCOUNTS PAYABLE - PILOT</HeaderText>
      <Spacer size={0.5} />
      <BorderedBox>
        <HeaderText color="primary" weight={'bold'} size={'medium'}>
          TOTAL PILOT TRIP EXPENSES {<FormatDollars dollars={totalDueToPilots} />}
        </HeaderText>
        <Spacer />
        <PilotTabs
          activeTab={activeTab}
          onChange={setActiveTab}
          pilotRoutes={pilotRoutes}
        />
        <Spacer />
        {activeTab === 'pic' && (
          <>
            {picExpensesComplete && (
              <Box width="fit-content" mb={1}>
                <ExpensesCompletePill expensesComplete={true} />
              </Box>
            )}
            <Adjustments
              expenseAdjustments={picAdjustments}
              dueToLabel={'PILOT'}
              dueToName={picName}
              control={picControl}
              theme={theme}
              remainingBalance={picRemaining}
              prefix={activeTab}
            />
          </>
        )}
        {activeTab === 'sic' && (
          <>
            {sicExpensesComplete && (
              <Box width="fit-content" mb={1}>
                <ExpensesCompletePill expensesComplete={true} />
              </Box>
            )}
            <Adjustments
              expenseAdjustments={sicAdjustments}
              dueToLabel={'PILOT'}
              dueToName={sicName}
              control={sicControl}
              theme={theme}
              remainingBalance={sicRemaining}
              prefix={activeTab}
            />
          </>
        )}
        <Spacer />
        {((activeTab === 'pic' && !picExpensesComplete) ||
          (activeTab === 'sic' && !sicExpensesComplete)) && <Spacer size={3} />}
        {activeTab === 'pic' && (
          <Row mt={theme.layout.space(0.25)} ph={theme.layout.space(0.5)}>
            <FitToContentButton
              disabled={picAdjustment === ''}
              onPress={savePicAdjustment}>
              Save Adjustment
            </FitToContentButton>
            {picHasStripeAccount && <Icon name="stripe-account" size={24} />}
            {picRemaining > 0 && picHasStripeAccount && expensesApproved ? (
              <FitToContentButton
                onPress={() => {
                  setSendingPicPayment(true);
                  processPaymentPic(picRemaining);
                }}
                loading={sendingPicPayment}
                disabled={sendingPicPayment}>
                Send Payment
              </FitToContentButton>
            ) : (
              <SendPaymentSpacer />
            )}
          </Row>
        )}
        {activeTab === 'sic' && (
          <Row mt={theme.layout.space(0.25)} ph={theme.layout.space(0.5)}>
            <FitToContentButton
              disabled={sicAdjustment === ''}
              onPress={saveSicAdjustment}>
              Save Adjustment
            </FitToContentButton>
            {sicHasStripeAccount && <Icon name="stripe-account" size={24} />}
            {sicRemaining > 0 && sicHasStripeAccount && expensesApproved ? (
              <FitToContentButton
                onPress={() => {
                  setSendingSicPayment(true);
                  processPaymentSic(sicRemaining);
                }}
                loading={sendingSicPayment}
                disabled={sendingSicPayment}>
                Send Payment
              </FitToContentButton>
            ) : (
              <SendPaymentSpacer />
            )}
          </Row>
        )}
      </BorderedBox>
    </Column>
  );
};

const SendPaymentSpacer = styled.View`
  padding-horizontal: 74px;
`;

const ScrollWrapper = styled.ScrollView(({ theme }) => ({
  height: theme.layout.space(20),
}));

const ownerDefaults = {
  ownerAdjustment: '',
  ownerNote: '',
};

export const AccountsReceivable = ({
  theme,
  ownerName,
  totalDueFromOwner,
  existingAdjustments,
  ownerAdjustments,
  totalOwnerAdjustments,
  update,
  save,
  showSendToOwner,
  sendToOwner,
  ownerHasStripeAccount,
}) => {
  const remainingBalance = totalDueFromOwner + totalOwnerAdjustments;
  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues: ownerDefaults,
  });
  const { isSubmitSuccessful } = useFormState({ control });

  const { ownerAdjustment, ownerNote } = watch();

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(ownerDefaults);
    }
  }, [isSubmitSuccessful, reset]);

  const newAdjustment = {
    amountDue: remainingBalance,
    dueToRole: UserRole.OWNER,
    dueToName: ownerName,
    dateCreated: new Date(),
  };

  const saveAdjustment = handleSubmit(
    useSaveAdjustmentCallback({
      existingAdjustments,
      newAdjustment,
      prefix: 'owner',
      update,
      save,
    }),
  );

  return (
    <Column>
      <HeaderText size={'medium'}>ACCOUNTS RECEIVABLE - CLIENT</HeaderText>
      <Spacer size={0.5} />
      <BorderedBox>
        <HeaderText color="primary" weight={'bold'} size={'medium'}>
          TOTAL REIMBURSABLE TRIP EXPENSES {<FormatDollars dollars={totalDueFromOwner} />}
        </HeaderText>
        <Spacer size={2} />
        <HeaderText h={48}>{ownerName}</HeaderText>
        <Adjustments
          expenseAdjustments={ownerAdjustments}
          dueToLabel={'MANAGER'}
          dueToName={ownerName}
          control={control}
          theme={theme}
          remainingBalance={remainingBalance}
          prefix={'owner'}
        />
        <Spacer size={4.5} />
        <Row>
          <FitToContentButton
            disabled={ownerAdjustment === ''}
            onPress={saveAdjustment}>
            Save Adjustment
          </FitToContentButton>
          {ownerHasStripeAccount && <Icon name="stripe-account" size={24} />}
          {showSendToOwner ? (
            <FitToContentButton onPress={sendToOwner}>
              Request {ownerHasStripeAccount ? 'Payment' : 'Approval'}
            </FitToContentButton>
          ) : (
            <RequestPaymentSpacer />
          )}
        </Row>
      </BorderedBox>
    </Column>
  );
};

const RequestPaymentSpacer = styled.View`
  padding-horizontal: 94.5px;
`;
