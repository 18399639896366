/**
 * @file
 * A component for a Select dropdown with a radio button next to it
 *
 * @format
 * @flow strict-local
 */

import React from 'react';
import Select from 'react-select';
import styled from '@emotion/native';

import { useTheme } from '@appComponents/theme';

type DropDownRadioProps = {
  value: ValueItem,
  valuePicker: ValueItem => any,
  onChange: ValueItem => void,
  options?: String[],
  checked: Boolean,
};

export type ValueItem = {
  label: string,
  value: string,
};

const DropDownRadioButton = ({
  value,
  valuePicker,
  onChange,
  options,
}: DropDownRadioProps) => {
  const theme = useTheme();
  return (
    <Wrapper>
      <SelectWrapper>
        <Select
          value={valuePicker(value)}
          options={options}
          // menuPortalTarget is needed to ensure the dropdown menu is on top and not behind everything else
          menuPortalTarget={document.body}
          onChange={onChange}
          styles={makeDropdownStyle(theme.colors)}
        />
      </SelectWrapper>
    </Wrapper>
  );
};

const makeDropdownStyle = colors => ({
  control: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: colors.background,
    color: colors.text,
    cursor: isDisabled ? 'not-allowed' : 'pointer',
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isSelected || isFocused ? colors.section : undefined,
    color: colors.text,
    cursor: isDisabled ? 'not-allowed' : 'pointer',
  }),
  placeholder: styles => ({
    ...styles,
    backgroundColor: colors.background,
    color: colors.text,
  }),
  singleValue: styles => ({
    ...styles,
    backgroundColor: colors.background,
    color: colors.text,
  }),
  menu: styles => ({
    ...styles,
    backgroundColor: colors.background,
    color: colors.text,
  }),
});

const Wrapper = styled.View`
  flex-direction: row;
  align-items: center;
`;

const SelectWrapper = styled.View`
  min-width: 200px;
`;

export default DropDownRadioButton;
