import React from 'react';

import { HelpBase } from './Help';
import Text from '@appComponents/Text';
import { ScrollView } from 'react-native';
import { Spacer } from '@appComponents/ScreenLayout';
import { useTheme } from '@appComponents/theme';

const Manual = () => {
  const theme = useTheme();
  return (
    <HelpBase>
      <ScrollView>
        <Section title="Setting up Your FlightApp! Account & Onboarding Users">
          <Spacer />
          <BoldText>Welcome to FlightApp!</BoldText>
          <Spacer />
          <MainText>
            While we hope the design of FlightApp! is intuitive, there are many
            layers and functions that may require some guidance as you explore
            the app. This user manual will help you navigate through them.
          </MainText>
          <ul>
            <li>
              <MainText>
                The initial setup is important because of the relationships
                between the different user categories and aircraft.
              </MainText>
            </li>
            <li>
              <MainText>
                The first step involves activating the Management Company
                account at this URL: <ManagersLink />
              </MainText>
            </li>
            <li>
              <MainText>
                The mobile app, available on the Apple App Store, requires an
                active Management Company to work. Search for “FlightApp!” in
                the app store.
              </MainText>
            </li>
          </ul>
          <Spacer />
          <Subtitle sTitle="User Categories" theme={theme} />
          <Spacer />
          <MainText>
            There are four user categories in FlightApp!, each with its own
            interface and capabilities:
          </MainText>
          <ul>
            <li>
              <BoldText>Management Company (Manager): </BoldText>
              <MainText>
                Accesses the "Manager App" via a web app at <ManagersLink />.
                This app displays a significant amount of data and is best
                accessed using a computer or a large iPad. It is not practical
                to use the Manager App on a phone.
              </MainText>
            </li>
            <Definition
              term="Clients: "
              // def="The individuals who own the aircraft use the mobile app."
              def="The individuals who book trips use the mobile app."
            />
            <Definition term="Passengers: " def="Use the mobile app." />
            <Definition term="Pilots: " def="Use the mobile app." />
          </ul>
          <MainText style={Italic}>Note</MainText>
          <MainText>
            : In the documentation, Clients, Passengers, and Pilots are
            collectively referred to as <BoldText>CPP</BoldText>.
          </MainText>
          <Spacer />
          <Subheading text="Important Notes:" />
          <ul>
            <li>
              <MainText>
                The Management Company account must be activated before other
                accounts (Clients, Pilots, Passengers) can be set up. The mobile
                apps will not work unless they are connected to a Management
                Company.
              </MainText>
            </li>
            <li>
              <MainText>
                After activating the Management Company account, a “Company ID”
                will be created, visible at the top right corner of the Manager
                app. The Company ID always starts with the letter “C.”
              </MainText>
            </li>
            <li>
              <MainText>
                Share this ID with anyone who needs to connect to the Management
                Company’s FlightApp! account. It is a required field when
                activating the mobile app for Clients, Pilots, and Passengers
                (CPP).
              </MainText>
            </li>
          </ul>
          <Spacer />
          <MainText>
            There are <BoldText>three steps</BoldText> to fully set up
            FlightApp!:
          </MainText>
          <Spacer />
          <Subheading text="Step 1: User Setup" />
          <Spacer />
          <BoldText>For CPP Users:</BoldText>
          <ol>
            <li>
              <BoldText>Download the FlightApp! mobile app </BoldText>
              <MainText>from the Apple App Store (currently not available on Android). Be sure to include the "!" when searching for "FlightApp!".</MainText>
            </li>
            <li>
              <MainText>Follow this process to onboard CPP users:</MainText>
              <ul>
                <li>
                  <MainText>
                    On the <BoldText>Home Page</BoldText>, click on “Sign up”
                    (green text below the “Login” button).
                  </MainText>
                </li>
                <li>
                  <MainText>
                    On the <BoldText>Create Account Page</BoldText>, choose your
                    role: “Client,” “Pilot,” or “Passenger.”
                  </MainText>
                </li>
                <li>
                  <MainText>
                    Enter the{' '}
                    <BoldText>Management Company’s Company ID</BoldText>, then
                    input your name, email, phone number, and password.
                  </MainText>
                </li>
                <li>
                  <MainText>
                    Accept the <BoldText>Terms & Conditions</BoldText> and{' '}
                    <BoldText>Privacy Policy</BoldText>.
                  </MainText>
                </li>
                <li>
                  <MainText>Click “Create Account.”</MainText>
                </li>
                <li>
                  <MainText>
                    A message will appear in the app: “Your account is being
                    reviewed by the Manager. You will be notified once
                    approved.”
                  </MainText>
                </li>
                <li>
                  <MainText>
                    In the Manager App, the user’s request will appear under the{' '}
                    <BoldText>Account Request</BoldText> tab (left navigation
                    menu), along with their name, email, phone number, and role.
                    The Manager can approve or change the user’s role if
                    necessary. Roles cannot be changed later; if a mistake is
                    made, the user must be deleted and sign up again.
                  </MainText>
                </li>
              </ul>
            </li>
          </ol>
          <Spacer />
          <BoldText style={Italic}>Note: </BoldText>
          <MainText>There are two categories for Passengers:</MainText>
          <ul>
            <Definition
              term="App Passengers"
              def=": Set up as described above."
            />
            <Definition
              term="Guest Passengers"
              def=": Will not use the mobile app and are associated only with Clients."
            />
            <li>
              <MainText>
                For more details on how App Passengers and Guest Passengers work, see the “Building a Trip” section below.
              </MainText>
            </li>
          </ul>
          <Spacer />
          <BoldText>For Additional Managers:</BoldText>
          <ol>
            <li>
              <BoldText>Join your company’s FlightApp! account</BoldText>
              <MainText>
                {' '}
                at <ManagersLink />.
              </MainText>
            </li>
            <li>
              <MainText>Select “Join Company” under the Login button.</MainText>
            </li>
            <li>
              <MainText>
                Enter the <BoldText>Company ID</BoldText>, then fill in your
                name, email, phone number, and password, and accept the terms.
              </MainText>
            </li>
            <li>
              <MainText>
                A message will display: “Your manager must approve your account. Please check with them for status.”
              </MainText>
            </li>
            <li>
              <MainText>
                In the Manager App, the request will appear in the{' '}
                <BoldText>Account Request</BoldText> tab, showing the name,
                email, phone number, and the role “Manager.” This role can only
                be accepted or rejected; it cannot be reassigned to an CPP role.
              </MainText>
            </li>
          </ol>
          <Spacer />
          <MainText style={Italic}>Note: </MainText>
          <MainText>
            Clients, Pilots, and App Passengers cannot be added directly through
            the Manager’s app. They must register via their mobile apps and be
            accepted by the Manager, ensuring proper association with the
            correct Management Company.
          </MainText>
          <Spacer />
          <Subheading text="Step 2: Adding Aircraft" />
          <Spacer />
          <MainText>Users (CPPs) must be assigned to an aircraft to use FlightApp! After the user setup, the next step is to add at least one aircraft:</MainText>
          <Spacer />
          <ol>
            <li>
              <MainText>
                In the Manager App, navigate to the <BoldText>Aircraft</BoldText> tab from the left navigation menu.
              </MainText>
            </li>
            <li>
              <MainText>
                Click the <BoldText>Add Aircraft</BoldText> button at the top-right corner.
              </MainText>
            </li>
            <li>
              <MainText>There are two tabs to complete:</MainText>
              <ul>
                <Definition
                  term="Aircraft Details"
                  def=': Fill out all required fields (except "Current Fuel" level), ensuring the correct fuel units are selected.'
                />
                <li>
                  <BoldText>Clients</BoldText>
                  <MainText>
                    : In the <BoldText>Select a Client</BoldText> text box,
                    choose the relevant Client(s) from the list. If more than
                    one Client is assigned, designate a{' '}
                    <BoldText>Primary Client</BoldText>. If a Client is missing
                    from the list, they have not yet been onboarded to your
                    account (see User Setup).
                  </MainText>
                </li>
              </ul>
            </li>
          </ol>
          <MainText style={Italic}>Note: </MainText>
          <MainText>
            Pilots can enter maintenance squawks and other data for the
            aircraft, which will be appended to the trip information and
            available in the Manager App.
          </MainText>
          <Spacer />
          <Subheading text="Important Notes:" />
          <ul>
            <li>
              <MainText>
                For Clients to schedule trips via the mobile app, they must be
                assigned to the correct aircraft.
              </MainText>
            </li>
            <li>
              <MainText>
                Pilots must be assigned to the correct aircraft to schedule
                trips or receive trip requests.
              </MainText>
            </li>
          </ul>
          <Spacer />
          <Subheading text="Step 3: Setting up Accounting for Aircraft" />
          <Spacer />
          <MainText>
            When a new aircraft is added, an entry is automatically created in
            the <BoldText>Accounting</BoldText> tab for that aircraft.
          </MainText>
          <Spacer />
          <ol>
            <li>
              <MainText>
                In the Manager App, navigate to the{' '}
                <BoldText>Accounting</BoldText> tab, then click on the{' '}
                <BoldText>Expense Set Up</BoldText> tab.
              </MainText>
            </li>
            <li>
              <MainText>
                You'll see default <BoldText>Expense Categories</BoldText>{' '}
                (Fuel, Auto Transport, Lodging, Food, Other). You can rename,
                delete, or add new categories.
              </MainText>
            </li>
            <li>
              <MainText>
                Set up <BoldText>Payment Methods</BoldText> for each aircraft:
              </MainText>
              <ul>
                <li>
                  <MainText>
                    Click on the aircraft name to view default payment methods (e.g., Pilot Card, Pilot Cash, Client Card, Company Card).
                  </MainText>
                </li>
                <li>
                  <MainText>
                    Customize payment methods as needed (e.g., renaming “Pilot
                    Credit Card” to “Pilot – John Spencer CC” to make
                    reimbursement processes clearer).
                  </MainText>
                </li>
                <li>
                  <MainText>
                    Ensure all Pilots and Clients involved with payment are
                    included in the <BoldText>Payment Methods</BoldText>{' '}
                    section, and assign each method to the appropriate person.
                  </MainText>
                </li>
              </ul>
            </li>
          </ol>
          <Spacer />
          <Subheading text="Accounting Features" />
          <ul>
            <Definition
              term="Pilot mobile app"
              def=": Allows for trip expense entry, receipt image capture, and setting up a Stripe account for instant expense reimbursements."
            />
            <Definition
              term="Client mobile app"
              def=": Facilitates receipt of trip expense requests, reviewing individual expenses, flagging items, and Stripe-based instant reimbursements."
            />
            <Definition
              term="Manager web app"
              def=": Provides real-time visibility of expenses, allows for expense reconciliation, and supports instant transaction processing via Stripe."
            />
          </ul>
          <Spacer />
          <Subtitle sTitle="Building a Trip" />
          <Spacer />
          <MainText>
            Once at least one aircraft, Client, and Pilot have been onboarded
            into your company’s FlightApp! system, you can build a trip
            itinerary and share it with all participants.
          </MainText>
          <Spacer />
          <Subheading text="Note:" />
          <ul>
            <li>
              <MainText>
                Each trip is automatically assigned a{' '}
                <BoldText>Trip ID</BoldText> starting with the letter “T,” which
                is visible across all apps and cannot be changed.
              </MainText>
            </li>
            <li>
              <MainText>
                Clients, Pilots, and Managers can all build trips.
              </MainText>
            </li>
          </ul>
          <Spacer />
          <Subheading text="Clients Building a Trip via the Mobile App:" />
          <ol>
            <li>
              <MainText>
                On the mobile app’s home screen, click the{' '}
                <BoldText>Trip Builder</BoldText> tile (bottom-right), then
                click the “+ Trip Builder” button (green text).
              </MainText>
            </li>
            <li>
              <MainText>
                Enter a <BoldText>Trip Name</BoldText>, select an aircraft from
                the list (assigned to you during the “Add Aircraft” step), and
                add <BoldText>Trip Notes</BoldText>
                (requests for the Manager and Pilot).
              </MainText>
            </li>
            <li>
              <MainText>Add Passengers:</MainText>
              <ul>
                <li>
                  <MainText>
                    <BoldText>App Passengers</BoldText>: Those linked to the
                    Client during the User Setup process will appear in the{' '}
                    <BoldText>Previous Passenger List</BoldText>.
                  </MainText>
                </li>
                <li>
                  <MainText>
                    <BoldText>Guest Passengers</BoldText>: Also appear in the{' '}
                    <BoldText>Previous Passenger List</BoldText>, indicated by a
                    “G”. Add new Guest Passengers by clicking{' '}
                    <BoldText>Add Passenger</BoldText> (only the first and last
                    name are required fields).
                  </MainText>
                </li>
                <li>
                  <MainText>
                    You can add documents (e.g., passports) for Guest
                    Passengers, and, if necessary, toggle the "security" option
                    to allow Pilots access to these documents.
                  </MainText>
                </li>
                <li>
                  <MainText>
                    After adding passengers, designate one as the{' '}
                  </MainText>
                  <BoldText>Lead Passenger</BoldText>
                  <MainText>
                    {' then click the back arrow (<) to save the list.'}
                  </MainText>
                </li>
              </ul>
            </li>
          </ol>
          <Spacer />
          <MainText>
            In future releases, it will be possible to convert Guest Passengers
            to App Passengers.
          </MainText>
          <Spacer />
          <Subheading text="Adding Trip Legs:" />
          <ol>
            <li>
              <MainText>
                A trip must have at least one leg. You can add up to seven legs
                for a single trip; if more are needed, create a new trip.
              </MainText>
            </li>
            <li>
              <MainText>
                Specify the <BoldText>From/To</BoldText> locations (typically an
                airport code, though a city name can also be used),{' '}
                <BoldText>Departure Date</BoldText>,{' '}
                <BoldText>Departure Time</BoldText>, and{' '}
                <BoldText>Time Zone</BoldText>.
              </MainText>
            </li>
            <li>
              <MainText>
                Add or remove passengers from specific legs by using the{' '}
                <BoldText>Edit Leg Passengers</BoldText> feature. (It is
                required to have a Lead Passenger if passengers are included.)
              </MainText>
            </li>
            <li>
              <MainText>
                After adding all legs and passenger details, click{' '}
                <BoldText>Review Trip</BoldText> (green button).
              </MainText>
            </li>
            <li>
              <MainText>The trip status will be marked as “Draft.”</MainText>
            </li>
            <li>
              <MainText>
                You can now add <BoldText>Trip Notes</BoldText>,{' '}
                <BoldText>Trip Documents</BoldText>, and review any active
                aircraft squawks.
              </MainText>
            </li>
            <li>
              <MainText>
                Once satisfied with the details, press{' '}
                <BoldText>Send to Manager</BoldText>.
              </MainText>
            </li>
          </ol>
          <MainText>
            The trip will remain in the <BoldText>Requested</BoldText> tab of
            the Client’s app until the Manager processes it and changes the trip
            state to <BoldText>Upcoming</BoldText>.
          </MainText>
          <Spacer />
          <Subheading text="Managers Building a Trip via the Web App:" />
          <ol>
            <li>
              <BoldText>Navigate to the Trips Tab</BoldText>
              <ul>
                <li>
                  <MainText>
                    Start in the <BoldText>Trips</BoldText> tab located in the
                    left navigation menu.
                  </MainText>
                </li>
              </ul>
            </li>
            <li>
              <BoldText>Click "Build Trip"</BoldText>
              <ul>
                <li>
                  <MainText>
                    Select <BoldText>Build Trip</BoldText> in the top right
                    corner.
                  </MainText>
                </li>
              </ul>
            </li>
            <li>
              <BoldText>(1) Legs Tab</BoldText>
              <ul>
                <li>
                  <MainText>
                    <BoldText>Select a Client</BoldText> from the dropdown menu.
                  </MainText>
                </li>
                <li>
                  <MainText>
                    <BoldText>Name the trip.</BoldText>
                  </MainText>
                </li>
                <li>
                  <MainText>
                    <BoldText>Add required data </BoldText>(Flight Time is
                    optional).
                  </MainText>
                </li>
                <li>
                  <MainText>
                    You can add up to <BoldText>7 legs</BoldText> for a single
                    trip. If more legs are needed, create a new trip.
                  </MainText>
                </li>
                <li>
                  <MainText>
                    Once all legs are entered, move to the next step.
                  </MainText>
                </li>
              </ul>
            </li>
            <li>
              <BoldText>(2) Aircraft Tab</BoldText>
              <ul>
                <li>
                  <MainText>
                    <BoldText>Select an aircraft</BoldText> from the list by
                    using the radio button.
                  </MainText>
                </li>
                <li>
                  <MainText>
                    The <BoldText>Available Aircraft</BoldText> list shows all
                    aircraft that are not scheduled during the trip duration and
                    have no unresolved squawks (UAA status).
                  </MainText>
                </li>
                <li>
                  <MainText>
                    The <BoldText>Unavailable Aircraft</BoldText> list includes
                    aircraft that are either scheduled for part or all of the
                    trip or have a UAA squawk status. Note: an unavailable
                    aircraft can still be scheduled with an override.
                  </MainText>
                </li>
              </ul>
            </li>
            <li>
              <BoldText>(3) Passengers Tab</BoldText>
              <ul>
                <li>
                  <MainText>
                    The system assumes that the <BoldText>Client</BoldText> will
                    be a passenger. If this isn’t the case, you can remove them
                    from the trip here.
                  </MainText>
                </li>
                <li>
                  <MainText>
                    To <BoldText>add passengers</BoldText>, click{' '}
                    <BoldText>Add Passengers</BoldText>.
                  </MainText>
                  <ul>
                    <li>
                      <MainText>
                        Any <BoldText>App or Guest Passengers</BoldText>{' '}
                        associated with the Client will be listed in the
                        dropdown menu.
                      </MainText>
                    </li>
                    <li>
                      <MainText>
                        If a Passenger is not listed in the dropdown, you’ll
                        need to take additional steps to onboard them (explained
                        elsewhere in the manual).
                      </MainText>
                    </li>
                  </ul>
                </li>
                <li>
                  <MainText>
                    <BoldText>
                      All passengers are assumed to travel all legs
                    </BoldText>{' '}
                    of the trip. You can add or drop passengers between legs if
                    necessary. Once a passenger is added at an intermediate leg,
                    they are assumed to travel for the rest of the trip unless
                    manually removed from future legs.
                  </MainText>
                </li>
              </ul>
            </li>
            <li>
              <BoldText>(4) Pilots Tab</BoldText>
              <ul>
                <li>
                  <MainText>
                    Click on <BoldText>Add Pilot</BoldText>.
                  </MainText>
                </li>
                <li>
                  <MainText>
                    Any Pilot already assigned to the selected aircraft will be
                    available in the dropdown.
                  </MainText>
                </li>
                {/* <li>
                  <MainText>
                    At least <BoldText>one Pilot is required</BoldText> to save
                    the trip.
                  </MainText>
                </li> */}
                <li>
                  <MainText>
                    Designate one Pilot as <BoldText>PIC</BoldText> (Pilot in
                    Command) using the radio button.
                  </MainText>
                </li>
                <li>
                  <MainText>
                    A trip is limited to a maximum of{' '}
                    <BoldText>2 Pilots</BoldText>.
                  </MainText>
                </li>
              </ul>
            </li>
            <li>
              <BoldText>Save and Send</BoldText>
              <ul>
                <li>
                  <MainText>
                    Once all the details are filled in, click{' '}
                    <BoldText>Save and Send</BoldText> (bottom right) to send
                    the trip details to the assigned Pilots' mobile apps for
                    review and acceptance.
                  </MainText>
                </li>
              </ul>
            </li>
            <li>
              <BoldText>Trip Tabs for Different Roles</BoldText>
              <ul>
                <li>
                  <MainText>The trip will now appear in:</MainText>
                  <ul>
                    <li>
                      <MainText>
                        <BoldText>Manager’s Trips tab</BoldText>: Draft section.
                      </MainText>
                    </li>
                    <li>
                      <MainText>
                        <BoldText>Pilot’s mobile app</BoldText>: Requested tab.
                      </MainText>
                    </li>
                    <li>
                      <MainText>
                        <BoldText>Client’s app</BoldText>: Requested tab.
                      </MainText>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <BoldText>Trip Acceptance</BoldText>
              <ul>
                <li>
                  <MainText>
                    Each Pilot can choose to <BoldText>Accept</BoldText> or{' '}
                    <BoldText>Reject</BoldText> the trip.
                  </MainText>
                </li>
                <li>
                  <MainText>
                    The Client can also <BoldText>Accept</BoldText> or{' '}
                    <BoldText>Reject</BoldText> the trip.
                  </MainText>
                </li>
                <li>
                  <MainText>
                    The status of the Pilots and Client can be seen in the{' '}
                    <BoldText>Manager App’s Trips</BoldText> tab.
                  </MainText>
                </li>
              </ul>
            </li>
          </ol>
          <Spacer />
          <Subheading text="Pilot and Client Status" />
          <Spacer />
          <ul>
            <Definition
              term="Acknowledged"
              def=": The trip has been acknowledged by the Manager."
            />
            <Definition
              term="Requested"
              def=": The Pilot has been sent the trip request but has not opened it in their mobile app."
            />
            <Definition
              term="Seen"
              def=": The Pilot has reviewed the trip request but has not accepted or rejected it. (Or, the Pilot has seen changes made to the itinerary.)"
            />
            <Definition
              term="Updated"
              def=": The Pilot has been sent an updated itinerary."
            />
            <Definition
              term="Accepted"
              def=": The Pilot has accepted the trip."
            />
          </ul>
          <Spacer />
          <BoldText>Changing Trip Status</BoldText>
          <ul>
            <li>
              <MainText>
                Once the Manager has confirmed the Client and Pilot's
                acceptance, the Manager should change the trip state from{' '}
                <BoldText>Draft</BoldText> to <BoldText>Upcoming</BoldText> by
                selecting the menu (three dots "…") on the far right of the trip
                row.
              </MainText>
            </li>
            <li>
              <MainText>
                This change will move the trip to the{' '}
                <BoldText>Upcoming</BoldText> tab in the apps for the Client,
                Pilot, and Passengers.
              </MainText>
            </li>
          </ul>
          <Spacer />
          <Subheading text="Manager’s App: Left Navigation Menu Overview" />
          <Spacer />
          <ol>
            <li>
              <BoldText>Trips</BoldText>
              <ul>
                <li>
                  <MainText>
                    The Trips tab includes the following sub-tabs:
                  </MainText>
                </li>
                <ul>
                  <Definition term="All" def=": Displays all trips." />
                  <Definition
                    term="Requested"
                    def=": Shows trip requests from Clients."
                  />
                  <li>
                    <BoldText>Draft</BoldText>
                    <MainText>
                      : Trips are automatically placed in this tab when:
                    </MainText>
                    <ol>
                      <li>
                        <MainText>
                          A Client builds a trip and the Manager acknowledges
                          it.
                        </MainText>
                      </li>
                      <li>
                        <MainText>
                          The Manager builds a trip and sends it to the Client
                          and Pilots for review and acceptance.
                        </MainText>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <BoldText>Upcoming</BoldText>
                    <MainText>
                      : Once all trip participants are confirmed, the Manager
                      should move the trip to <BoldText>Upcoming</BoldText> by
                      selecting the three white dots on the trip. This action
                      moves the trip to the Upcoming tab for all participants.
                    </MainText>
                  </li>
                  <li>
                    <BoldText>Active</BoldText>
                    <MainText>
                      : When a trip is underway, the Manager should move it to{' '}
                      <BoldText>Active</BoldText>. This will move the trip to
                      the Active tab for all users.
                    </MainText>
                  </li>
                  <li>
                    <BoldText>Completed</BoldText>
                    <MainText>
                      : After a trip is finished, the Manager should move it to{' '}
                      <BoldText>Completed</BoldText>, which shifts the trip to
                      the Completed tab for all users.
                    </MainText>
                  </li>
                  <Definition
                    term="Archive"
                    def=": Once a trip is fully completed and closed out, the Manager can archive the trip."
                  />
                </ul>
              </ul>
            </li>
            <li>
              <BoldText>Messages</BoldText>
              <ul>
                <li>
                  <MainText>
                    Each trip has a unique and private messaging channel.
                  </MainText>
                </li>
                <li>
                  <MainText>
                    All participants (Clients, Pilots, Passengers, and Managers)
                    can view and send messages related to the trip.
                  </MainText>
                </li>
                <li>
                  <MainText>
                    The <BoldText>Messages tab</BoldText> shows all trips
                    (regardless of state) in a left-hand column. Clicking on a
                    trip will display the messages.
                  </MainText>
                </li>
                <li>
                  <MainText>
                    Each message is tagged with the author and timestamp.
                  </MainText>
                </li>
              </ul>
            </li>
            <li>
              <BoldText>Aircraft</BoldText>
              <ul>
                <li>
                  <MainText>
                    Displays all aircraft managed by the Management Company.
                  </MainText>
                </li>
                <li>
                  <MainText>
                    To add a new aircraft, see the "Adding Aircraft" section
                    above.
                  </MainText>
                </li>
                <li>
                  <MainText>
                    To edit an existing aircraft, click on the aircraft’s tail
                    number (in blue text) and then select the pencil icon (edit)
                    to:
                  </MainText>
                  <ul>
                    <li>
                      <MainText>
                        Edit the aircraft's basic information.
                      </MainText>
                    </li>
                    <li>
                      <MainText>
                        Add new Pilots to the aircraft's roster.
                      </MainText>
                    </li>
                    <li>
                      <MainText>Adjust the fuel status.</MainText>
                    </li>
                    <li>
                      <MainText>Assign or remove aircraft Clients.</MainText>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <BoldText>Clients</BoldText>
              <ul>
                <li>
                  <MainText>
                    All Clients associated with the Management Company will
                    appear here.
                  </MainText>
                </li>
                <li>
                  <MainText>
                    Note: Clients can only be added through their mobile app.
                    (See "User Setup" for details.)
                  </MainText>
                </li>
                <li>
                  <BoldText>Editing Clients</BoldText>
                  <MainText>: The main reasons to edit an Client are:</MainText>
                  <ul>
                    {/* <li>
                      <MainText>
                        Update their email address or phone number.
                      </MainText>
                    </li> */}
                    {/* <li>
                      <MainText>
                        Update their name or weight.
                      </MainText>
                    </li> */}
                    <li>
                      <MainText>
                        Add Client documents such as passports.
                      </MainText>
                    </li>
                    <li>
                      <MainText>
                        Add Guest Passengers and their documents.
                      </MainText>
                    </li>
                    <li>
                      <MainText>
                        Adjust the document status to "Security Document".
                        Security Documents are available to Pilots in their app
                        during the trip (e.g., passport details for a
                        Passenger).
                      </MainText>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <BoldText>Pilots</BoldText>
              <ul>
                <li>
                  <MainText>
                    All Pilots associated with the Management Company will
                    appear here.
                  </MainText>
                </li>
                <li>
                  <MainText>
                    Pilots can only be added through the Pilot’s mobile app.
                  </MainText>
                </li>
                <li>
                  <BoldText>Editing Pilots</BoldText>
                  <MainText>: The main reasons to edit a Pilot are:</MainText>
                  <ul>
                    {/* <li>
                      <MainText>
                        Update their email address or phone number.
                      </MainText>
                    </li> */}
                    {/* <li>
                      <MainText>
                        Update their name or weight.
                      </MainText>
                    </li> */}
                    <li>
                      <MainText>
                        Add licenses, ratings, and other documents such as
                        passports.
                      </MainText>
                    </li>
                    <li>
                      <MainText>
                        Adjust a document’s status to "Security Document".
                      </MainText>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <BoldText>Passengers</BoldText>
              <ul>
                <li>
                  <MainText>Shows App Passengers.</MainText>
                </li>
              </ul>
            </li>
            <li>
              <BoldText>Managers</BoldText>
              <ul>
                <li>
                  <MainText>
                    Lists all Managers in the Management Company’s account.
                  </MainText>
                </li>
                <li>
                  <MainText>
                    Managers can only be added via the Manager’s web app by
                    creating a User Account.
                  </MainText>
                </li>
                <li>
                  <MainText>Managers can be archived from this tab.</MainText>
                </li>
                <li>
                  <MainText>
                    A Manager can update their own email, phone number, and
                    password in the <BoldText>Settings</BoldText> tab when
                    logged in directly.
                  </MainText>
                </li>
              </ul>
            </li>
            <li>
              <BoldText>Account Requests</BoldText>
              <ul>
                <li>
                  <MainText>
                    This is where mobile app users (Clients, Passengers, Pilots)
                    appear when they are attempting to connect to the Management
                    Company.
                  </MainText>
                </li>
                <li>
                  <MainText>
                    The Manager must either accept or reject the request by
                    clicking on the edit dots on the far right side of the row.
                  </MainText>
                </li>
                <li>
                  <MainText>
                    If the user accidentally selected the wrong role during
                    signup, the Manager can correct it here. Once a user is
                    added, their role cannot be changed.
                  </MainText>
                </li>
              </ul>
            </li>
            <li>
              <BoldText>Accounting</BoldText>
              <ul>
                <li>
                  <BoldText>Top Level</BoldText>
                  <MainText>
                    : The Accounting tab includes three primary sections:{' '}
                    <BoldText>Clients</BoldText>, <BoldText>Pilots</BoldText>,
                    and <BoldText>Expense Set Up</BoldText>.
                  </MainText>
                </li>
                <li>
                  <BoldText>Clients and Pilots Tabs:</BoldText>
                  <ul>
                    <li>
                      <MainText>
                        When you select either the <BoldText>Clients</BoldText>{' '}
                        or <BoldText>Pilots</BoldText> tab, you will see a list
                        of all the respective Clients and Pilots.
                      </MainText>
                    </li>
                    <li>
                      <MainText>
                        Each entry displays the{' '}
                        <BoldText>current total dollar value</BoldText> due
                        either{' '}
                        <BoldText>
                          from a Client to the Management Company
                        </BoldText>{' '}
                        or{' '}
                        <BoldText>
                          from the Management Company to a Pilot
                        </BoldText>
                        .
                      </MainText>
                    </li>
                    <li>
                      <MainText>
                        To view further details, click on a user’s name, which
                        will take you to the <BoldText>Second Level</BoldText>{' '}
                        of the accounting details.
                      </MainText>
                    </li>
                    <li>
                      <MainText>
                        <BoldText>Second Level</BoldText>: At this level, you
                        will see a list of trips along with the{' '}
                        <BoldText>current dollar value</BoldText> due to or from
                        the associated user. To get more granular information,
                        click on a specific <BoldText>Trip ID</BoldText>, which
                        will take you to the <BoldText>Third Level</BoldText> of
                        accounting details.
                      </MainText>
                    </li>
                    <li>
                      <MainText>
                        <BoldText>Third Level</BoldText>: The third level view
                        is divided into three sections:
                      </MainText>
                    </li>
                    <ul>
                      <Spacer />
                      <MainText>
                        <BoldText>Top Section</BoldText> – Displays individual
                        expenses:
                      </MainText>
                      <ul>
                        <li>
                          <MainText>
                            To add a new expense, click the{' '}
                            <BoldText>Add Expense</BoldText> button located in
                            the top right corner.
                          </MainText>
                        </li>
                        <li>
                          <MainText>
                            For more details on a specific expense item, click
                            inside the row. From here, you can:
                          </MainText>
                          <ul>
                            <li>
                              <MainText>
                                View and edit the expense information.
                              </MainText>
                            </li>
                            <li>
                              <MainText>
                                <BoldText>Flag</BoldText> or{' '}
                                <BoldText>Unflag</BoldText> an expense item.
                              </MainText>
                            </li>
                            <li>
                              <MainText>
                                Add comments to an expense (you must first flag
                                the expense to enable comments).
                              </MainText>
                            </li>
                            <li>
                              <MainText>
                                Delete an expense if necessary.
                              </MainText>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <Spacer />
                      <MainText>
                        <BoldText>
                          Bottom Left Section: Accounts Payable – Pilot:
                        </BoldText>
                      </MainText>
                      <ul>
                        <li>
                          <MainText>
                            This section is for viewing and adjusting trip
                            expense reimbursements owed to a Pilot.
                          </MainText>
                        </li>
                        {/* <li>
                          <MainText>
                            Take note of the <BoldText>DUEs values</BoldText>{' '}
                            for each expense.
                          </MainText>
                        </li> */}
                        <li>
                          <MainText>
                            When a payment is made to reimburse a Pilot, create
                            an <BoldText>Adjustment Entry</BoldText> and add an
                            optional description in the{' '}
                            <BoldText>Notes</BoldText> section.
                          </MainText>
                        </li>
                        <li>
                          <MainText>
                            Ensure you click{' '}
                            <BoldText>Save Adjustment</BoldText> after making
                            any changes.
                          </MainText>
                        </li>
                      </ul>
                      <Spacer />
                      <MainText>
                        <BoldText>
                          Bottom Right Section: Accounts Receivable – Client:
                        </BoldText>
                      </MainText>
                      <ul>
                        <li>
                          <MainText>
                            This section is where trip reimbursements due to the
                            Management Company are processed and noted.
                          </MainText>
                        </li>
                      </ul>
                    </ul>
                  </ul>
                </li>
              </ul>
            </li>
          </ol>
          <Spacer />
          <Subheading text="Calendar" />
          <Spacer />
          <MainText>
            The <BoldText>Calendar</BoldText> tab allows you to view all trips
            in a calendar format:
          </MainText>
          <ul>
            <li>
              <MainText>
                By default, the calendar opens to the{' '}
                <BoldText>current month</BoldText>, but you can navigate forward
                or backward using the buttons in the top right corner.
              </MainText>
            </li>
            <li>
              <MainText>
                The calendar can be viewed by <BoldText>month</BoldText>,{' '}
                <BoldText>week</BoldText>, or <BoldText>day</BoldText>.
              </MainText>
            </li>
            <li>
              <MainText>
                You can click on a specific <BoldText>Trip ID</BoldText> to view
                the trip details.
              </MainText>
            </li>
            <li>
              <MainText>
                The calendar can be filtered by <BoldText>user</BoldText> or{' '}
                <BoldText>aircraft</BoldText> using the filters located at the
                bottom left.
              </MainText>
            </li>
            {/* <li>
              <MainText>
                Trips are automatically added to the user’s default iPhone
                calendar associated with their email address.
              </MainText>
            </li>
             */}
            <li>
              <MainText>
                Any updates made by the Manager to a trip are reflected in the
                calendar.
              </MainText>
            </li>
          </ul>
          <Spacer />
          <Subheading text="Archived" />
          <Spacer />
          <ul>
            <li>
              <MainText>
                Archived trips, aircraft, Clients, Pilots, and Passengers are
                stored in the <BoldText>Archived</BoldText> section.
              </MainText>
            </li>
            <li>
              <MainText>
                You can <BoldText>permanently delete</BoldText> any archived
                items from here, but note that all associated data will be lost.
              </MainText>
            </li>
            <li>
              <MainText>
                <BoldText>Note</BoldText>: Trip messages have their own section,
                and these will also be deleted if a trip is permanently deleted.
              </MainText>
            </li>
          </ul>
          <Spacer />
          <Subheading text="Settings" />
          <Spacer />
          <ul>
            <li>
              <MainText>
                The logged-in Manager can update their <BoldText>name</BoldText>
                , <BoldText>email</BoldText>, <BoldText>phone number</BoldText>,{' '}
                <BoldText>weight</BoldText>, and <BoldText>password</BoldText>{' '}
                in the <BoldText>Settings</BoldText> tab.
              </MainText>
            </li>
            <li>
              <MainText>
                Manage <BoldText>Subscription</BoldText> and{' '}
                <BoldText>Expense Payment Processing</BoldText> from this
                section as well.
              </MainText>
            </li>
          </ul>
        </Section>
      </ScrollView>
    </HelpBase>
  );
};

const Italic = {
  fontStyle: 'italic',
};

const BoldText = ({ children }) => (
  <MainText weight="bold">{children}</MainText>
);

const MainText = ({
  size = 'medium',
  weight,
  color = 'text',
  style = {},
  children,
}) => (
  <Text size={size} weight={weight} color={color} style={style}>
    {children}
  </Text>
);

const ManagersLink = () => <LinkText text="https://managers.flightapp.io" />;

const LinkText = ({ text }) => (
  <a href={text} target="_blank">
    <MainText color="primary">{text}</MainText>
  </a>
);

const Section = ({ title, children }) => (
  <>
    <Text color="primary" weight="bold" size="large">
      {title}
    </Text>
    <ul>{children}</ul>
  </>
);

const Subheading = ({ text }) => (
  <Text color="mobilePrimary" size="mediumLarge">
    {text}
  </Text>
);

const Subtitle = ({ sTitle }) => (
  <Text color="mobilePrimary" weight="bold" size="mediumLarge">
    {sTitle}
  </Text>
);

const Definition = ({ term, def, children }) => (
  <li>
    <BoldText>{term}</BoldText>
    <MainText>{def}</MainText>
    {children && <ul>{children}</ul>}
  </li>
);

export default Manual;
