import React from 'react';
import Text from '@appComponents/Text';
import { BadgeDiv } from './Settings';

export const createAircraftQuantityOptions = initialValue => {
  const minValue = Math.max(1, initialValue);
  const maxValue = Math.max(20, minValue);
  return Array.from({ length: maxValue - minValue + 1 }, (_, i) => ({
    name: (i + minValue).toString(),
    value: i + minValue,
  }));
};

export const formatAmount = amount => {
  if (!amount) {
    return 'N/A';
  }

  return `$${amount.toFixed(2)}`;
};

export const SubscriptionStatus = ({
  subscriptionData,
  inTrialCodePeriod,
  isInCanceledPeriod,
  company,
}) => {
  if (!subscriptionData && !inTrialCodePeriod) {
    return null;
  }

  if (isInCanceledPeriod) {
    return (
      <BadgeDiv color="#DB3A34">
        <Text>Canceled</Text>
      </BadgeDiv>
    );
  }

  if (
    company?.subscriptionStatus === 'active' ||
    company?.subscriptionStatus === 'complete'
  ) {
    return (
      <BadgeDiv color="#1EAF85">
        <Text>Active</Text>
      </BadgeDiv>
    );
  }
  if (company?.subscriptionStatus === 'trialing' || inTrialCodePeriod) {
    return (
      <BadgeDiv>
        <Text>Trial</Text>
      </BadgeDiv>
    );
  }

  return (
    <BadgeDiv color="#DB3A34">
      <Text>Canceled</Text>
    </BadgeDiv>
  );
};
