/**
 * @file
 * A component for displaying expenses related to a trip
 *
 * @format
 * @flow strict-local
 */

import React, { useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';
import DataTable, { Row as TableRow, Cell } from '@appComponents/DataTable';
import { NumericFormat } from 'react-number-format';
import styled from '@emotion/native';
import { List } from 'react-native-paper';
import { FitToContentButton } from '@appComponents/Button';
import { Box, Spacer } from '@appComponents/ScreenLayout';
import Text from '@appComponents/Text';
import { FormatDollars } from '@appUtils/accounting';
import { ImageViewer } from '@appComponents/ImageViewer';
import _ from 'lodash';
import { ExpansionIcon, HeaderText } from '@webComponents/Accounting/General';
import { FittedDialog } from '@appComponents/Dialog';
import { useExpenseDialog } from '@webComponents/Accounting/ExpenseDialog';
import { useCommentsDialog } from './ExpenseComments';
import { ExpenseFlagIcon } from '@appComponents/ExpenseFlagIcon';
import { deleteExpenseComments } from '@appUtils/expenseComments';
import { useMyCompanyRef } from '@appUtils/api';

const ExpenseTable = ({
  unsortedExpenses,
  sortedExpenses,
  updateSort,
  expandedExpenses,
  setExpandedExpenses,
  images,
  updateTrip,
  saveTrip,
  company,
  aircraft,
  tripId,
  tripExpenseComments,
  setAnyDialogOpen,
}) => {
  const flagExpense = useCallback(
    expense => {
      const expenses = _.cloneDeep(unsortedExpenses);
      const currentExpense = _.find(expenses, ['id', expense.id]);
      currentExpense.isFlagged = currentExpense?.isFlagged ? false : true;
      updateTrip({ expenses });
      saveTrip();
    },
    [saveTrip, unsortedExpenses, updateTrip],
  );

  const RowRenderer = useCallback(
    ({ item, children }) => (
      <ExpenseInfo
        expense={item}
        children={children}
        flagExpense={flagExpense}
        expandedExpenses={expandedExpenses}
        setExpandedExpenses={setExpandedExpenses}
        images={images}
        expenses={unsortedExpenses}
        saveTrip={saveTrip}
        updateTrip={updateTrip}
        company={company}
        aircraft={aircraft}
        tripId={tripId}
        tripExpenseComments={tripExpenseComments}
        setAnyDialogOpen={setAnyDialogOpen}
      />
    ),
    [
      aircraft,
      company,
      expandedExpenses,
      flagExpense,
      images,
      saveTrip,
      setAnyDialogOpen,
      setExpandedExpenses,
      tripExpenseComments,
      tripId,
      unsortedExpenses,
      updateTrip,
    ],
  );

  return (
    <DataTable
      data={sortedExpenses}
      RowComponent={RowRenderer}
      keyExtractor={keyExtractor}
      initialSort={initialSort}
      onSortChange={updateSort}
      headerStyle={tableHeaderStyle}
      emptyMessage={
        <Box ai="center" mt={1}>
          <Text>No Expenses</Text>
        </Box>
      }
      adjustHeader={false}>
      <TypeCell title="Type" path="type.name" sortable flex={columnFlex.type} />
      <LocationCell
        title="Location"
        path="location"
        sortable
        flex={columnFlex.location}
      />
      <PaidUsingCell
        title="Paid Using"
        path="paymentMethod.name"
        sortable
        flex={columnFlex.paidUsing}
      />
      <DescriptionCell
        title="Description"
        path="description"
        sortable
        flex={columnFlex.description}
      />
      <CostCell title="Cost" path="amount" sortable flex={columnFlex.cost} />
      <FuelCell
        title={`Fuel (${aircraft?.fuelUnits ?? 'gal'})`}
        path="fuelAmount"
        sortable
        flex={columnFlex.fuel}
      />
      <FlagCell
        title="Flag"
        path="isFlagged"
        sortable
        flex={columnFlex.flag}
        tripExpenseComments={tripExpenseComments}
      />
    </DataTable>
  );
};

const TypeCell = ({ item, ...cellProps }) => {
  return (
    <Cell {...cellProps}>
      <ExpenseListText>
        {item?.type?.name === '' ? '-' : item?.type?.name}
      </ExpenseListText>
    </Cell>
  );
};

const LocationCell = ({ item, ...cellProps }) => {
  return (
    <Cell {...cellProps}>
      <ExpenseListText>
        {item?.location === '' ? '-' : item?.location}
      </ExpenseListText>
    </Cell>
  );
};

const PaidUsingCell = ({ item, ...cellProps }) => {
  return (
    <Cell {...cellProps}>
      <ExpenseListText>{item?.paymentMethod?.name ?? '-'}</ExpenseListText>
    </Cell>
  );
};

const DescriptionCell = ({ item, ...cellProps }) => {
  return (
    <Cell {...cellProps}>
      <ExpenseListText>
        {item?.description === '' ? '-' : item?.description}
      </ExpenseListText>
    </Cell>
  );
};

const CostCell = ({ item, ...cellProps }) => {
  return (
    <Cell {...cellProps}>
      <FormatDollars
        dollars={item?.amount}
        renderText={value => <ExpenseListText>{value}</ExpenseListText>}
      />
    </Cell>
  );
};

const FuelCell = ({ item, ...cellProps }) => {
  return (
    <Cell {...cellProps}>
      <FuelDisplay fuel={item?.type?.fuelExpense ? item.fuelAmount : 'N/A'} />
    </Cell>
  );
};

const FlagCell = ({ item, tripExpenseComments, ...cellProps }) => {
  const hasComments = tripExpenseComments[item.id] ?? false;

  return (
    <Cell {...cellProps}>
      <ExpenseFlagIcon
        isFlagged={item?.isFlagged}
        hasComments={hasComments}
        paddingLeft="10px"
      />
    </Cell>
  );
};

const columnFlex = {
  type: 1.5,
  location: 2,
  paidUsing: 2,
  description: 4.5,
  cost: 2,
  fuel: 1.5,
  flag: 1,
};

export const initialSort = { title: 'id', dir: 'asc', path: 'id' };

const tableHeaderStyle = {
  paddingRight: '50px',
  paddingLeft: '76px',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const titlePath = {
  Date: 'date',
  Type: 'type.name',
  Location: 'location',
  'Paid Using': 'paymentMethod.name',
  Description: 'description',
  Cost: 'amount',
  Fuel: 'fuelAmount',
  Flag: 'isFlagged',
};

const keyExtractor = expense => expense.date;

const TableRowWrapper = styled(TableRow)(({}) => ({
  minHeight: 20,
  width: '100%',
}));

const FuelDisplay = ({ fuel }) =>
  fuel === 'N/A' ? (
    <ExpenseListText>{fuel}</ExpenseListText>
  ) : (
    <NumericFormat
      value={fuel}
      thousandSeparator={true}
      displayType="text"
      renderText={value => <ExpenseListText>{value}</ExpenseListText>}
    />
  );

const ExpenseListRow = ({ children }) => (
  <TableRowWrapper>{children}</TableRowWrapper>
);

const ExpenseAccordionContent = styled(Box)(({ theme }) => ({
  borderColor: theme.colors.background,
  borderWidth: 2,
  borderRadius: 5,
  marginTop: 1,
}));

const ExpenseListText = styled(Text)(({ theme }) => ({
  fontSize: theme.fonts.size.medium,
  textWrap: 'wrap',
}));

const ExpenseAccordion = styled(List.Accordion)``;

const ExpenseInfo = ({
  expense,
  children,
  flagExpense,
  expandedExpenses,
  setExpandedExpenses,
  images,
  expenses,
  saveTrip,
  updateTrip,
  company,
  aircraft,
  tripId,
  tripExpenseComments,
  setAnyDialogOpen,
}) => {
  const [companyRef] = useMyCompanyRef();
  const expanded = expandedExpenses[expense.id] ?? false;
  const hasComments = tripExpenseComments[expense.id] ?? false;
  const { dialogNode, openDialog } = useExpenseDialog({
    mode: 'Edit',
    expense,
    saveTrip,
    updateTrip,
    currentExpenses: expenses,
    expenseCategories: company?.config?.expenseTypes ?? [],
    paymentMethods: aircraft?.paymentMethods ?? [],
    tripId,
    fuelUnits: aircraft?.fuelUnits ?? 'gal',
    setAnyDialogOpen,
  });
  const { commentsDialogNode, openCommentsDialog } = useCommentsDialog({
    tripId,
    expense,
    setAnyDialogOpen,
  });

  const handlePress = () =>
    setExpandedExpenses({ ...expandedExpenses, [expense.id]: !expanded });

  const expenseImages = useMemo(
    () => _.filter(images, i => _.includes(expense.photoUrls, i.path)),
    [expense.photoUrls, images],
  );

  const deleteExpense = useDeleteExpense({
    expense,
    currentExpenses: expenses,
    updateTrip,
    saveTrip,
    companyRef,
    tripId,
  });

  const { deleteConfirmationNode, openConfirmation } = useDeleteConfirmation({
    deleteExpense,
  });

  return (
    <>
      <ExpenseAccordion
        title={<ExpenseListRow children={children} />}
        right={() => null}
        left={() => <ExpansionIcon name={expanded ? 'menu-up' : 'menu-down'} />}
        expanded={expanded}
        onPress={handlePress}>
        <ExpenseAccordionContent ph={2}>
          <Spacer />
          {dialogNode}
          {deleteConfirmationNode}
          {commentsDialogNode}
          <AccordionTopRow>
            <FitToContentButton onPress={openDialog}>EDIT</FitToContentButton>
            <FitToContentButton onPress={() => flagExpense(expense)} ml="10px">
              {expense?.isFlagged ? 'Unflag' : 'Flag'}
            </FitToContentButton>
            {(expense?.isFlagged || hasComments) && (
              <FitToContentButton
                onPress={() => {
                  openCommentsDialog();
                }}
                ml="10px">
                Comments
              </FitToContentButton>
            )}
            <FitToContentButton
              onPress={openConfirmation}
              color="secondary"
              ml="10px">
              DELETE
            </FitToContentButton>
          </AccordionTopRow>
          <ImageViewer
            images={expenseImages}
            canAddImage={false}
            displayFileNames
          />
        </ExpenseAccordionContent>
      </ExpenseAccordion>
    </>
  );
};

const useDeleteConfirmation = ({ deleteExpense }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const closeConfirmation = useCallback(() => setShowConfirmation(false), []);
  const openConfirmation = useCallback(() => setShowConfirmation(true), []);

  const deleteConfirmationNode = showConfirmation && (
    <DeleteConfirmationDialog
      closeConfirmation={closeConfirmation}
      deleteExpense={deleteExpense}
    />
  );

  return {
    deleteConfirmationNode,
    openConfirmation,
    closeConfirmation,
  };
};

const DeleteConfirmationDialog = ({ closeConfirmation, deleteExpense }) => (
  <FittedDialog
    visible
    title="Delete Expense"
    onDismiss={closeConfirmation}
    actionSlot={
      <>
        <FitToContentButton mr="10px" onPress={closeConfirmation}>
          Cancel
        </FitToContentButton>
        <FitToContentButton
          color="secondary"
          onPress={() => {
            closeConfirmation();
            deleteExpense();
          }}>
          Delete
        </FitToContentButton>
      </>
    }>
    <HeaderText>Are you sure you want to delete this expense?</HeaderText>
  </FittedDialog>
);

const useDeleteExpense = ({
  expense,
  currentExpenses,
  updateTrip,
  saveTrip,
  companyRef,
  tripId,
}) =>
  useCallback(() => {
    const expenses = _.filter(currentExpenses, e => e.id !== expense.id);
    deleteExpenseComments(companyRef, tripId, expense.id);
    updateTrip({ expenses });
    saveTrip();
  }, [companyRef, currentExpenses, expense.id, saveTrip, tripId, updateTrip]);

const AccordionTopRow = styled(View)(({}) => ({
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'flex-end',
}));

export default ExpenseTable;
