/**
 * @format
 * @flow strict-local
 */

import React, { Node, useCallback, useEffect, useMemo } from 'react';
import type { ViewStyle } from 'react-native';
import _ from 'lodash';
import styled from '@emotion/native';

import Radio from '@appComponents/Radio';
import List, { useGenericListControl, RemoveButton } from '@appComponents/List';
import { Box, Spacer } from '@appComponents/ScreenLayout';
import PassengerEdit from './PassengerEdit';
import type { PassengerItem } from './PassengerEdit';
import { getTripPassengers } from '@appUtils/trip';
import Text from '@appComponents/Text';
import AddBaggageComponent from './AddBaggageComponent';
import { useForm, useFieldArray } from 'react-hook-form';
import { formatNumber } from '@appUtils/numbers';

type PassengerListProps = {
  passengers: Array<PassengerItem & { isPendingAdd?: boolean }>,
  onAdd?: PassengerItem => void,
  canAdd?: Boolean,
  onRemove: PassengerItem => void,
  onReplace: ({ prev: PassengerItem, next: PassengerItem }) => void,
  setLead?: PassengerItem => void,
  style?: ViewStyle,
  numColumns?: number,
  ownerId?: String,
  aircraft: Object,
};

const MAX_PASSENGERS = 20;

const PassengerList = ({
  passengers = [],
  onReplace,
  setLead,
  onAdd,
  canAdd,
  onRemove,
  style,
  numColumns,
  ownerId,
  owner,
  aircraft,
}: PassengerListProps): Node => {
  const { items, hasPendingAdd, addEntry, removeEntry, removePending } =
    useGenericListControl({ items: passengers, onRemove });

  const keyExtractor = useCallback((item, i) => item.name || i, []);

  const renderItem = useCallback(
    ({ item, index }: PassengerListItem) => (
      <PassengerRow dir="row" width="100%" ai="center" mt={0.3} ph={0.5}>
        <Box dir="row" width={widths.name} ai="center">
          {setLead && (
            <LeadSelector
              value={item.name}
              checked={index === 0}
              onChange={() => !item.isPendingAdd && setLead(item)}
            />
          )}
          <PassengerEdit
            ownerId={ownerId}
            passenger={item}
            selectedPassengers={passengers}
            isLead={index === 0}
            onUpdate={({ isPendingAdd, ...passenger }: PassengerItem) => {
              if (isPendingAdd) {
                removePending();
                onAdd(passenger);
              } else {
                onReplace({ prev: item, next: passenger });
              }
            }}
            autoFocus={Boolean(onAdd)}
            owner={owner}
            aircraft={aircraft}
          />
        </Box>
        <Box width={widths.weight}>
          <Text size="medium">
            {_.isEmpty(item?.weight) ? 0 : item.weight} lbs
          </Text>
        </Box>
        {item?.name && <BaggageAndWeight item={item} onUpdate={onReplace} />}
        <RemovePassengerButton onPress={() => removeEntry({ item })} />
      </PassengerRow>
    ),
    [
      aircraft,
      onAdd,
      onReplace,
      owner,
      ownerId,
      passengers,
      removeEntry,
      removePending,
      setLead,
    ],
  );

  return (
    <List
      items={items}
      onAdd={addEntry}
      canAdd={canAdd}
      disableAdd={hasPendingAdd}
      addButtonLabel="Add Passenger"
      renderItem={renderItem}
      maxItems={MAX_PASSENGERS}
      style={style}
      numColumns={numColumns}
      keyExtractor={keyExtractor}
      ItemSeparatorComponent={ItemSeparator}
      ListHeaderComponent={
        <Box dir="row">
          <HeaderItem label="Passenger Name" width={widths.name} />
          <HeaderItem label="Passenger Weight" width={widths.weight} />
          <HeaderItem label="Bags & Weight" width={widths.bags} />
          <HeaderItem label="Total Baggage Weight" width={widths.bagWeight} />
        </Box>
      }
    />
  );
};

const BaggageAndWeight = ({ item, onUpdate }) => {
  const { control } = useForm({
    defaultValues: { baggage: item?.baggage ?? [] },
  });
  const { fields, remove, append, update } = useFieldArray({
    control,
    name: 'baggage',
  });

  useEffect(() => {
    if (onUpdate) {
      const filteredBaggage = fields.map(f => _.omit(f, 'id'));

      if (!_.isEqual(item?.baggage, filteredBaggage)) {
        onUpdate({
          prev: item,
          next: { ...item, baggage: filteredBaggage },
        });
      }
    }
  }, [fields, item, onUpdate]);

  return (
    <>
      <Box width={widths.bags}>
        <AddBaggageComponent
          baggage={fields}
          update={update}
          remove={remove}
          append={append}
          control={control}
          canUpdate={Boolean(onUpdate)}
        />
      </Box>
      <Box width={widths.bagWeight}>
        <Text size="medium">
          {formatNumber({ value: getBaggageTotal(fields) })} lbs
        </Text>
      </Box>
    </>
  );
};

const getBaggageTotal = (baggage = []) =>
  _.sumBy(baggage, b => (_.isEmpty(b?.weight) ? 0 : Number(b.weight)));

const widths = {
  name: '30%',
  weight: '15%',
  bags: '25%',
  bagWeight: '15%',
};

const HeaderItem = ({ label, width }) => (
  <Box width={width}>
    <Text size="mediumLarge" weight="bold">
      {label}
    </Text>
  </Box>
);

const PassengerRow = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.section,
  borderRadius: theme.roundness,
}));

const RemovePassengerButton = styled(RemoveButton)(({ theme }) => ({
  marginLeft: 'auto',
  marginRight: theme.layout.gap(3),
  backgroundColor: theme.colors.section,
}));

type PassengerListItem = { item: PassengerItem, index: number };

export const usePassengerListControl = ({ legs = [], changeLegs }) => {
  const addPassenger = useCallback(
    (passenger, legIndex = 0) =>
      changeLegs(
        legs.map((leg, i) => {
          // add the passenger to current and following legs
          if (
            i >= legIndex &&
            _.every(leg.passengers, p => p.name !== passenger.name)
          ) {
            return {
              ...leg,
              passengers: [...leg.passengers, passenger],
            };
          }

          return leg;
        }),
      ),
    [changeLegs, legs],
  );

  const removePassenger = useCallback(
    (passenger, legIndex = 0) =>
      changeLegs(
        legs.map((leg, i) => {
          // remove the passenger from current and following legs
          if (i >= legIndex) {
            return {
              ...leg,
              passengers: leg.passengers.filter(
                ({ name }) => name !== passenger.name,
              ),
            };
          }

          return leg;
        }),
      ),
    [changeLegs, legs],
  );

  const replacePassenger = useCallback(
    ({ prev, next }) =>
      changeLegs(
        legs.map(leg => ({
          ...leg,
          passengers: leg.passengers.map(p => {
            if (p.name === prev.name) {
              return next;
            }

            return p;
          }),
        })),
      ),
    [changeLegs, legs],
  );

  const allPassengers = useMemo(() => getTripPassengers(legs), [legs]);

  return {
    addPassenger,
    removePassenger,
    replacePassenger,
    allPassengers,
  };
};

const ItemSeparator = () => <Spacer size={0} />;

const LeadSelector: Radio = styled(Radio)({
  paddingHorizontal: 0,
  paddingVertical: 0,
});

export default PassengerList;
