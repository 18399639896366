import _ from 'lodash';
import { getMyUserDoc, getUserData, getUserDoc } from './api';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';

/**
 * Edit a single passenger stored in an owner's user document
 * @param {Object} existingRecord old version of the passenger
 * @param {Object} updates new version of the passenger
 * @param {string} [uid] (optional) uid for the owner, defaults to current user
 */
export const editPassenger = async (existingRecord, updates, uid) => {
  const user = await getUserData(uid);
  const passengers = user?.passengers ?? [];
  const currentPassengerIndex = passengers.findIndex(
    p => p.name === existingRecord.name,
  );

  const updatedPassenger = {
    name: `${updates.firstName} ${updates.lastName}`,
    email: updates.email,
    phoneNumber:
      updates.phoneNumber &&
      parsePhoneNumberFromString(updates.phoneNumber, 'US').number,
    documents: _.map(updates.documents, doc => ({
      ...doc,
      // Exclude the local `uri` property. It's only meant to be used for local previews
      // and should not be saved to the server. Saving it breaks image loading functionality
      images: doc.images.map(image => ({
        path: image.path,
        name: image.name,
      })),
    })),
  };
  if (updates.weight !== undefined) {
    updatedPassenger.weight = updates.weight;
  }
  if (currentPassengerIndex > -1) {
    passengers[currentPassengerIndex] = updatedPassenger;
  } else {
    passengers.push(updatedPassenger);
  }

  await saveOwnerPassengers(passengers, uid);
};

/**
 * Removes a single passenger from an owner's user document
 * @param {Object} currentPassenger complete passenger object
 * @param {string} [uid] (optional) uid for the owner, defaults to current user
 */
export const removePassenger = async (currentPassenger, uid) => {
  const user = await getUserData(uid);
  const existingPassengers = user?.passengers ?? [];

  const passengers = existingPassengers.filter(
    p => p.name !== currentPassenger.name,
  );

  await saveOwnerPassengers(passengers, uid);
};

/**
 * Save all the passengers for an owner
 * @param {Object[]} passengers all the passenger records
 * @param {string} [uid] (optional) uid for the owner, defaults to current user
 */
export const saveOwnerPassengers = async (passengers, uid) => {
  let userRef;
  if (uid) {
    userRef = getUserDoc(uid);
  } else {
    userRef = getMyUserDoc();
  }
  await userRef.update({ passengers });
};

export const userToPassengerData = ({ user, ownerAssociated = true }) => {
  let role;
  if (user?.role) {
    role = user.role;
  } else if (user?.roles && user?.roles[0]) {
    role = user.roles[0];
  }
  return {
    name: `${user?.firstName} ${user?.lastName}` ?? '',
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email ?? '',
    phoneNumber: user?.phoneNumber ?? '',
    id: user?.id ?? '',
    weight: user?.weight ?? '',
    ownerAssociated: ownerAssociated,
    role: role,
  };
};
