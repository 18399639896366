/* eslint-disable quotes */
/**
 * @format
 * @flow strict-local
 */
import React from 'react';
import { ScrollView } from 'react-native';
import styled from '@emotion/native';
import { useTheme } from '@emotion/react';
import { List } from 'react-native-paper';

import { MainSurface, ScreenLayout, Spacer } from '@appComponents/ScreenLayout';
import Text from '@appComponents/Text';
import Button from '@appComponents/Button';
import { supportEmail } from '@appUtils/app';

export const HelpBase = ({ children }) => {
  return (
    <ScreenLayout alignItems="stretch">
      <ScrollView>
        <HelpSurface>
          <Button
            aSelf="flex-start"
            color="accent"
            href={`mailto:${supportEmail}?subject=FlightApp! Manager Help`}>
            Contact FlightApp! Support
          </Button>
          <MonitoringText>
            Support email monitored 9 to 5 US Central time, we can usually respond
            in 4 business hours.
          </MonitoringText>
          <Spacer />
          {children}
        </HelpSurface>
      </ScrollView>
    </ScreenLayout>
  );
};

const Help = () => {
  return (
    <ScreenLayout alignItems="stretch">
      <HelpSurface>
        <Button
          aSelf="flex-start"
          color="accent"
          href={`mailto:${supportEmail}?subject=FlightApp! Manager Help`}>
          Contact FlightApp! Support
        </Button>
        <MonitoringText>
          Support email monitored 9 to 5 US Central time, we can usually respond
          in 4 business hours.
        </MonitoringText>
        <Spacer />
        <ScrollView>
          <List.Accordion title="FAQs">
            <FaqItem
              title="Why is a Pilot Unavailable?"
              description={`When an assigned pilot shows up as "Unavailable" it's because they are assigned on another trip that overlaps with this trip.`}
            />
            <FaqItem
              title="Why is an Aircraft Unavailable?"
              description={`An aircraft is shown as "Unavailable" either because it's on a trip that overlaps with this one or it has an Unflightworthy (UFW) squawk.`}
            />
            <FaqItem
              title="How does sorting trips by Pilot Status work?"
              description={`When you sort by "Pilot Status" on the Trips page it sorts by the status of the Pilot in Command for that trip.`}
            />
            <FaqItem
              title="How are aircraft fuel values updated?"
              description="The current fuel shown in each aircraft is set in the aircraft section of the manager app and is also updated by pilots when they set fuel values on the latest trip for that aircraft."
            />
            <FaqItem
              title="Why can't I add expense receipt images?"
              description="When recording an expense in a trip an expense category is required, so you won't have the option of adding images to that record until you choose an expense category."
            />
            <FaqItem
              title="How do I add comments to an expense?"
              description="Expenses must be flagged or already have comments in order to add new comments. Flag the expense to enable comments."
            />
            <FaqItem
              title="Why can't I add comments to a new expense?"
              description="Comments can only be added to existing expenses, not new ones. Save the expense first, then you'll be able to add comments to it."
            />
            <FaqItem
              title="How do expense adjustment values work?"
              description={`The total due is based on expenses and does not factor in adjustments.
The left column of the last row lists the remaining balance based on expenses and adjustments. That value can also be found in column D of the last row in the adjustments export CSV.
The left column of all rows, except the last row, reflects the remaining balance at the time that adjustment was made.
If an expense is added after making adjustments that amount will be reflected in the total due and the last row and but will not be reflected in the rows above it.`}
              isLast
            />
          </List.Accordion>
          <Spacer size={0.25} />
          <List.Accordion title="Best Practices">
            <AccordionContent>
              <BestPractice>
                A trip is only expected to last up to ten days.
              </BestPractice>
              <BestPractice>
                A leg is assumed to end on the same day it starts.
              </BestPractice>
              <BestPractice>
                If your clients don't know the airport codes or which airport
                you'll be using they can type in city names for origins and
                destinations, and you can change them to the specific airport
                codes. Then you can communicate in the notes so they show up to
                the right airport.
              </BestPractice>
              <BestPractice noMargin>
                Client Status Explanations
                <ul>
                  <li>
                    Draft: A trip made by the client and not yet shared with
                    managers.
                  </li>
                  <li>
                    Manager Requested: A manager has created a trip and shared
                    it with the client.
                  </li>
                  <li>Canceled: The trip has been canceled.</li>
                  <li>
                    Accepted: The client has accepted the manager-made trip.
                  </li>
                  <li>
                    Rejected: The client has rejected the manager-made trip.
                  </li>
                  <li>
                    Updated: A manager has made an update to the trip and the
                    client hasn't seen it yet.
                  </li>
                  <li>Seen: The client has seen updates made by a manager.</li>
                  <li>
                    Requested: A client has made the trip, and shared it with
                    the managers, but managers haven't acknowledged it yet.
                  </li>
                  <li>
                    Acknowledged: A manager has acknowledged a client-made trip.
                  </li>
                </ul>
              </BestPractice>
              <BestPractice noMargin isLast>
                Pilot Status Explanations
                <ul>
                  <li>
                    Manager Requested: A manager has created a trip and shared
                    it with the pilot.
                  </li>
                  <li>
                    Manager Canceled: The trip has been canceled by a manager.
                  </li>
                  <li>Accepted: The pilot has accepted the trip.</li>
                  <li>Rejected: The pilot has rejected the trip.</li>
                  <li>
                    Updated: A manager has made an update to the trip and the
                    pilot hasn't seen it yet.
                  </li>
                  <li>Seen: The pilot has seen updates made by a manager.</li>
                </ul>
              </BestPractice>
            </AccordionContent>
          </List.Accordion>
        </ScrollView>
      </HelpSurface>
    </ScreenLayout>
  );
};

export const FaqItem: typeof List.Item = ({
  title,
  description,
  isLast = false,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <>
      <List.Item
        title={title}
        description={description}
        left={props => <List.Icon {...props} icon="help" />}
        descriptionStyle={{
          color: theme.colors.dark,
        }}
        descriptionNumberOfLines={99}
        {...rest}
      />
      {!isLast && <DarkSpacer size={0.25} />}
    </>
  );
};

const DarkSpacer = styled(Spacer)(({ theme }) => ({
  backgroundColor: theme.colors.background,
}));

export const HelpSurface = styled(MainSurface)(({ theme }) => ({
  padding: theme.layout.space(1),
  color: theme.colors.dark,
}));

const AccordionContent = styled.View(({ theme }) => ({
  padding: theme.layout.space(0.5),
  borderWidth: 1,
  borderColor: theme.colors.border,
  borderStyle: 'solid',
}));

export const HelpText = styled(Text)(
  ({ theme, noMargin = false, bold = false, color = theme.colors.dark }) => ({
    color: color,
    fontSize: 15,
    lineHeight: 16,
    letterSpacing: 0.4,
    marginBottom: noMargin ? 0 : theme.layout.space(0.5),
    fontWeight: bold ? 800 : 400,
  }),
);

export const HelpHeader = styled(Text)(({ theme }) => ({
  color: theme.colors.dark,
  fontSize: 20,
}));

export const BestPractice = ({ children, noMargin = false, isLast = false }) => (
  <>
    <HelpText noMargin={noMargin}>{children}</HelpText>
    {!isLast && <DarkSpacer size={0.25} />}
    <Spacer size={0.5} />
  </>
);

const MonitoringText = styled(Text)(({ theme }) => ({
  color: theme.colors.dark,
  fontSize: 15,
  lineHeight: 16,
  letterSpacing: 0.4,
  marginTop: theme.layout.space(1),
  marginBottom: theme.layout.space(1),
}));

export default Help;
