/**
 * @file
 * Person dropdown/search component
 *
 * @format
 * @flow strict-local
 */

import React, { Ref } from 'react';
import { ViewStyle } from 'react-native';
import _ from 'lodash';

import SearchField from '@appComponents/SearchField';
import { useCompanyUsers } from '@appUtils/api';
import { UserRole } from '@appUtils/tripConverter';
import { Box } from '@appComponents/ScreenLayout';
import { AvailableRange, FilterDescription } from './components';
import { useTheme } from '@appComponents/theme';

type SelectUserProps = {
  selected?: PersonItem,
  onChange?: PersonItem => void,
  style?: ViewStyle,
  innerRef?: Ref,
};

export type PersonItem = {
  firstName: string,
  lastName: string,
  roles: UserRole[],
};

const SelectUser = ({
  selected,
  onChange,
  style,
  innerRef,
  disabled,
}: SelectUserProps) => {
  const [options, loading] = useOptions();
  const theme = useTheme();

  return (
    <Box dir="column">
      <SearchField
        innerRef={innerRef}
        isClearable
        placeholder="Select a user"
        value={selected}
        options={options}
        loading={loading}
        getOptionValue={o => o.id}
        isValidNewOption={() => false}
        onChange={onChange}
        style={{ ...styles, ...style }}
        controlStyle={getControlStyle(theme)}
        disabled={disabled}
      />
      {Boolean(selected) && <FilterDescription entity={selected.role} />}
      {Boolean(selected) && <AvailableRange user={selected} entity="user" />}
    </Box>
  );
};

const useOptions = () => {
  const [users, loading] = useCompanyUsers(ROLES);

  const mapped = _.chain(users)
    .map(u => {
      const roles = u.roles.filter(role => ROLES.includes(role)).sort();

      return {
        label: `${u.firstName} ${u.lastName}`,
        roles,
        id: u.id,
      };
    })
    .sortBy('label')
    .sortBy('roles')
    .reduce(
      (res, next) => {
        next.roles.forEach(role =>
          res[role].push({
            ...next,
            role,
          }),
        );
        return res;
      },
      {
        [UserRole.OWNER]: [],
        [UserRole.PILOT]: [],
        [UserRole.MANAGER]: [],
        [UserRole.EMPLOYEE]: [],
        [UserRole.PASSENGER]: [],
      },
    )
    .value();

  const options = [
    { label: 'Clients', options: mapped[UserRole.OWNER] },
    {
      label: 'Pilots',
      options: [...mapped[UserRole.PILOT]],
    },
    {
      label: 'Managers',
      options: [...mapped[UserRole.MANAGER]],
    },
    {
      label: 'Employees',
      options: [...mapped[UserRole.EMPLOYEE]],
    },
    {
      label: 'Passengers',
      options: [...mapped[UserRole.PASSENGER]],
    },
  ];
  return [options, loading];
};

const styles = {
  height: 40,
  minWidth: 280,
};

const getControlStyle = theme => ({
  backgroundColor: theme.colors.background,
  color: theme.colors.text,
});

const ROLES = [
  UserRole.OWNER,
  UserRole.PILOT,
  UserRole.MANAGER,
  UserRole.EMPLOYEE,
  UserRole.PASSENGER,
];

export default SelectUser;
