/**
 * @format
 * @flow strict-local
 */

import React, { useCallback, useMemo, useState } from 'react';
import { Drawer } from '@appComponents/navigation';
import UserMenu from '@appComponents/UserMenu';
import Button from '@appComponents/Button';

import Sidebar from '../../components/Sidebar';
import Blank from './Blank';
import Playground from './Playground';
import Trips from './Trips';
import TripBuilder from './TripBuilder';
import Trip from './Trip';
import Aircraft from './Aircraft';
import AircraftDetails from './AircraftDetails';
import Owners from './Owners';
import OwnerDetails from './Owners/OwnerDetails';
import Pilots from './Pilots';
import PilotDetails from './Pilots/PilotDetails';
import Passengers from './Passengers';
import PassengerDetails from './Passengers/PassengerDetails';
import Managers from './Managers';
import ManagerDetails from './Managers/ManagerDetails';
import AccountRequests from './AccountRequests';
import Accounting from './Accounting';
import Calendar from './Calendar';
import ArchivedTrips from './ArchivedTrips';
import Help from './Help/Help';
import Subscription from './Subscription';
import { LiveMessages, ArchivedMessages } from './Messages';
import ArchivedManagers from './ArchivedManagers';
import ArchivedOwners from './ArchivedOwners';
import ArchivedPassengers from './ArchivedPassengers';
import ArchivedPilots from './ArchivedPilots';
import ArchivedAircrafts from './ArchivedAircrafts';
import { useMyData } from '@appUtils/api';
import Quickstart from './Help/Quickstart';
import Manual from './Help/Manual';
import Faq from './Help/Faq';
import BestPractices from './Help/BestPractices';
import TermsAndDefinitions from './Help/TermsAndDefinitions';
import Settings from './Settings/Settings';

// Todo: extract the drawer toggle here

export default function Screens() {
  const [user, loading] = useMyData();
  const { drawerWidth, drawerContent } = useDrawerContent();
  const options = useScreenOptions({ drawerWidth, user, loading });

  return (
    <Drawer.Navigator
      drawerContent={drawerContent}
      defaultStatus="open"
      backBehavior="history"
      screenOptions={options}>
      <Drawer.Group screenOptions={groupOptions}>
        <Drawer.Screen name="Trips" component={Trips} />
        <Drawer.Screen name="Messages" component={LiveMessages} />
        <Drawer.Screen name="Aircraft" component={Aircraft} />
        <Drawer.Screen name="Clients" component={Owners} />
        <Drawer.Screen name="Pilots" component={Pilots} />
        <Drawer.Screen name="Passengers" component={Passengers} />
        <Drawer.Screen name="Managers" component={Managers} />
        <Drawer.Screen name="Account Requests" component={AccountRequests} />
        <Drawer.Screen name="Accounting" component={Accounting} />
        <Drawer.Screen name="Calendar" component={Calendar} />
        <Drawer.Screen name="Settings" component={Settings} />
        <Drawer.Screen name="Help" component={Help} />
        {__DEV__ && <Drawer.Screen name="Playground" component={Playground} />}
        <Drawer.Screen name="Subscription" component={Subscription} />
      </Drawer.Group>

      <Drawer.Group screenOptions={groupOptions}>
        <Drawer.Screen
          name="Trip Builder"
          component={TripBuilder}
          options={{ unmountOnBlur: false }}
        />
        <Drawer.Screen name="Trip" component={Trip} />
        <Drawer.Screen name="Aircraft Details" component={AircraftDetails} />
        <Drawer.Screen name="Manager Details" component={ManagerDetails} />
        <Drawer.Screen name="Client Details" component={OwnerDetails} />
        <Drawer.Screen name="Passenger Details" component={PassengerDetails} />
        <Drawer.Screen name="Pilot Details" component={PilotDetails} />

        <Drawer.Screen name="Archived Trips" component={ArchivedTrips} />
        <Drawer.Screen name="Archived Messages" component={ArchivedMessages} />
        <Drawer.Screen name="Archived Aircraft" component={ArchivedAircrafts} />
        <Drawer.Screen name="Archived Managers" component={ArchivedManagers} />
        <Drawer.Screen name="Archived Clients" component={ArchivedOwners} />
        <Drawer.Screen
          name="Archived Passengers"
          component={ArchivedPassengers}
        />
        <Drawer.Screen name="Archived Pilots" component={ArchivedPilots} />
        <Drawer.Screen name="Help Quickstart" component={Quickstart} />
        <Drawer.Screen
          name="Help Terms and Definitions"
          component={TermsAndDefinitions}
        />
        <Drawer.Screen name="FlightApp! User Manual" component={Manual} />
        <Drawer.Screen name="Help FAQ" component={Faq} />
        <Drawer.Screen name="Help Best-Practices" component={BestPractices} />
      </Drawer.Group>
    </Drawer.Navigator>
  );
}

export const useDrawerContent = () => {
  const [drawerWidth, setDrawerWidth] = useState(260);

  const drawerContent = useCallback(
    props => (
      <Sidebar
        drawerWidth={drawerWidth}
        setDrawerWidth={setDrawerWidth}
        {...props}
      />
    ),
    [drawerWidth],
  );

  return { drawerWidth, drawerContent };
};

export const useScreenOptions = ({ drawerWidth = 260, user, loading }) =>
  useMemo(
    () => ({
      drawerType: 'permanent',
      headerLeft: false,
      unmountOnBlur: true,
      headerRight: headerRightProps => (
        <UserMenu user={!loading && user} {...headerRightProps} />
      ),
      drawerStyle: {
        width: drawerWidth,
      },
    }),
    [drawerWidth, loading, user],
  );

const BackButtonStyle = {
  backgroundColor: 'transparent',
};

export const groupOptions = ({ route, navigation }) => ({
  headerLeft: () =>
    navigation.canGoBack() ? (
      <Button
        icon="back-arrow-circled"
        style={BackButtonStyle}
        onPress={() => {
          // It's not clear why we need to go back twice, but if we don't the user will stay on the same screen and the would need to press the back button twice to actually go back
          navigation.goBack();
          navigation.goBack();
        }}
      />
    ) : null,
  title: route.params?.title ?? route.name,
});
