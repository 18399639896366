/**
 * @file The chat component that displays messages for a trip and allows sending messages
 */
import React, { Fragment, useCallback, useState } from 'react';
import styled from '@emotion/native';
import {
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
} from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import _ from 'lodash';
import { DateTime } from 'luxon';
import {
  useExpenseComments,
  addExpenseComment,
} from '@appUtils/expenseComments';
import { useTheme } from '@appComponents/theme';
import { FittedDialog } from '@appComponents/Dialog';
import { getRoleString } from '@appUtils/user';

export const useCommentsDialog = ({ tripId, expense, setAnyDialogOpen }) => {
  const [showDialog, setShowDialog] = useState(false);
  const closeCommentsDialog = useCallback(() => {
    setShowDialog(false);
    setAnyDialogOpen(false);
  }, [setAnyDialogOpen]);
  const openCommentsDialog = useCallback(() => {
    setAnyDialogOpen(true);
    setShowDialog(true);
  }, [setAnyDialogOpen]);
  const theme = useTheme();

  const commentsDialogNode = showDialog && (
    <FittedDialog visible onDismiss={closeCommentsDialog} actionSlot={null}>
      <ExpenseComments
        visible
        height={theme.layout.space(25)}
        width={theme.layout.space(20)}
        tripId={tripId}
        expense={expense}
      />
    </FittedDialog>
  );

  return {
    commentsDialogNode,
    openCommentsDialog,
    closeCommentsDialog,
  };
};

type ExpenseCommentsProps = {
  tripId: String,
  height: Number,
  visible: Boolean,
  expense: Object,
  expenseComments: Object,
};

const ExpenseComments = ({
  tripId,
  expense,
  width,
  height,
}: ExpenseCommentsProps) => {
  const expenseComments = useExpenseComments(tripId, expense?.id);

  const useSubmitHandler = (textContent: String) => {
    addExpenseComment(expenseComments?.id, textContent, tripId, expense);
  };

  return (
    <ChatBox height={height} w={width}>
      <ChatContainer>
        <MessageList>
          {_.map(expenseComments?.comments, comment => {
            const formatedSentTime = DateTime.fromMillis(
              comment?.dateSent?.toMillis(),
            ).toFormat('t ZZZZ dd LLL yyyy');
            const name = comment?.author?.name?.split(' ')[0] ?? '';
            const role = comment?.author?.role;
            const updatedRole = getRoleString(role);
            const direction = 'incoming';
            return (
              <Fragment
                key={`${comment?.author?.id} ${comment?.dateSent.toMillis()}`}>
                <Message.Header
                  sentTime={formatedSentTime}
                  sender={`${name}, ${updatedRole}`}
                />
                <Message
                  className="message"
                  type="text"
                  model={{
                    direction,
                    message: comment?.text,
                  }}
                />
              </Fragment>
            );
          })}
        </MessageList>
        <MessageInput
          placeholder="Add flag comment..."
          attachButton={false}
          onSend={useSubmitHandler}
          disabled={!tripId}
        />
      </ChatContainer>
    </ChatBox>
  );
};

const ChatBox = styled.View(({ w, height }) => ({
  width: w ?? '100%',
  height,
}));

export default ExpenseComments;
