/**
 * @file
 * Search Field (Select) component used on Web
 *
 * @format
 * @flow strict-local
 */

import React, { useCallback, useMemo } from 'react';
import { lazy, Suspense } from 'react';
import type { StatelessFunctionalComponent } from 'react';
import merge from 'deepmerge';
import colorConverter from 'color';

import { useTheme } from '@appComponents/theme';
import { defaultSearchProps, SearchFieldProps } from './searchPropTypes';

/**
 * The main difference between these is the Sync version receives all the options and
 * applies filtration internally while the Async version calls `loadOptions` and expects
 * to receive the filtered options
 */
const AsyncSelect = lazy(() => import('react-select/async-creatable'));
const SyncSelect = lazy(() => import('react-select/creatable'));

const SearchField: StatelessFunctionalComponent<SearchFieldProps> = ({
  value,
  defaultValue,
  defaultOptions,
  options,
  autoFocus,
  isClearable,
  isOptionDisabled,
  isValidNewOption,
  placeholder,
  name,
  loadOptions,
  getOptionLabel,
  getOptionValue,
  formatCreateLabel,
  formatGroupLabel,
  noOptionsMessage,
  onChange,
  onBlur,
  onMenuOpen,
  onMenuClose,
  disabled,
  loading,
  style,
  controlStyle,
  optionStyle,
  innerRef,
  isRequired,
}) => {
  const Select = loadOptions ? AsyncSelect : SyncSelect;
  const hasError = isRequired && !defaultValue;
  const filterOption = (option, inputValue) => {
    if (!inputValue) {
      return true;
    }

    return (
      option.data.__isNew__ ||
      option?.data?.name?.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  return (
    <Suspense fallback={null}>
      <Select
        isSearchable
        openMenuOnFocus
        isClearable={isClearable}
        isDisabled={disabled}
        isLoading={loading}
        isOptionDisabled={isOptionDisabled}
        isValidNewOption={isValidNewOption}
        value={value}
        defaultValue={defaultValue}
        defaultOptions={defaultOptions}
        options={options}
        menuPortalTarget={document.body}
        placeholder={placeholder}
        name={name}
        loadOptions={loadOptions}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        formatCreateLabel={formatCreateLabel}
        formatGroupLabel={formatGroupLabel}
        filterOption={filterOption}
        noOptionsMessage={noOptionsMessage}
        onChange={onChange}
        onBlur={onBlur}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        autoFocus={autoFocus}
        theme={useCustomTheme()}
        styles={useCustomStyles({ style, controlStyle, optionStyle, hasError })}
        minMenuHeight={200}
        menuPlacement="auto"
        ref={innerRef}
      />
    </Suspense>
  );
};

const useCustomTheme = () => {
  const theme = useTheme();
  return useCallback(defaultTheme => merge(defaultTheme, theme), [theme]);
};

const useCustomStyles = ({ style, controlStyle, optionStyle, hasError }) => {
  const theme = useTheme();

  // Documentation for custom dropdown styling: https://react-select.com/home#custom-styles
  return useMemo(
    () => ({
      container: (base, state) => ({
        ...base,
        ...merge.all(Array.isArray(style) ? style : [style || {}]),
        ...theme.fonts.regular,
      }),
      control: (base, { isDisabled }) => ({
        ...base,
        ...controlStyle,
        backgroundColor: isDisabled
          ? theme.colors.disabled
          : controlStyle.backgroundColor,
        borderColor: hasError ? theme.colors.error : controlStyle.borderColor,
      }),
      indicatorSeparator: base => ({
        ...base,
        backgroundColor: controlStyle.borderColor,
      }),
      menu: base => ({
        ...base,
        ...theme.fonts.regular,
        ...controlStyle,
      }),
      // Chosen option in the base
      singleValue: (base, { isDisabled }) => ({
        ...base,
        color: controlStyle.color,
        backgroundColor: isDisabled
          ? theme.colors.disabled
          : controlStyle.backgroundColor,
      }),
      // Text shown in base when no option is selected
      placeholder: (base, { isDisabled }) => ({
        ...base,
        backgroundColor: isDisabled
          ? theme.colors.disabled
          : controlStyle.backgroundColor,
      }),
      // Highlight for selected option and mouse over in menu
      option: (base, { isFocused, isSelected, isDisabled }) => ({
        ...base,
        ...controlStyle,
        ...optionStyle,
        backgroundColor: isDisabled
          ? theme.colors.disabled
          : isSelected
          ? colorConverter(controlStyle.backgroundColor)
              .saturate(0.5)
              .lighten(1)
              .hex()
          : isFocused
          ? colorConverter(controlStyle.backgroundColor).lighten(2.5).hex()
          : controlStyle.backgroundColor,
      }),
      // User-entered text
      input: base => ({
        ...base,
        color: theme.colors.text,
      }),

      // Here are the remaining components that can be styled individually
      //  Doc: (https://react-select.com/styles#inner-components)

      // valueContainer: base => ({
      //   ...base,
      //   ...controlStyle,
      // }),
      // clearIndicator: base => ({
      //   ...base,
      //   ...controlStyle,
      // }),
      // dropdownIndicator: base => ({
      //   ...base,
      //   ...controlStyle,
      // }),
      // group: base => ({
      //   ...base,
      //   ...controlStyle,
      // }),
      // groupHeading: base => ({
      //   ...base,
      //   ...controlStyle,
      // }),
      // indicatorsContainer: base => ({
      //   ...base,
      //   ...controlStyle,
      // }),
      // indicatorSeparator: base => ({
      //   ...base,
      //   ...controlStyle,
      // }),
      // input: base => ({
      //   ...base,
      //   ...controlStyle,
      // }),
      // loadingIndicator: base => ({
      //   ...base,
      //   ...controlStyle,
      // }),
      // loadingMessage: base => ({
      //   ...base,
      //   ...controlStyle,
      // }),
      // menuList: base => ({
      //   ...base,
      //   ...controlStyle,
      // }),
      // menuPortal: base => ({
      //   ...base,
      //   ...controlStyle,
      // }),
      // multiValue: base => ({
      //   ...base,
      //   ...controlStyle,
      // }),
      // multiValueLabel: base => ({
      //   ...base,
      //   ...controlStyle,
      // }),
      // multiValueRemove: base => ({
      //   ...base,
      //   ...controlStyle,
      // }),
      // noOptionsMessage: base => ({
      //   ...base,
      //   ...controlStyle,
      // }),
    }),
    [
      style,
      theme.fonts.regular,
      theme.colors.disabled,
      theme.colors.error,
      theme.colors.text,
      controlStyle,
      hasError,
      optionStyle,
    ],
  );
};

SearchField.defaultProps = defaultSearchProps;

export type { ActionMeta } from './searchPropTypes';

export default SearchField;
