import React, { useEffect, useMemo } from 'react';
import type { Node } from 'react';
import styled from '@emotion/native';
import { useFilePicker } from 'use-file-picker';
import { ActivityIndicator } from 'react-native-paper';
import _ from 'lodash';

import { ImageButton } from '@appComponents/ImageViewer/ImageThumbnails';
import Text from '@appComponents/Text';

const AddImageButton = ({
  onImageResult,
  addImageText = '+ Add New File',
}: AddImageButtonProps): Node => {
  const [openFileSelector, { loading, errors, plainFiles, clear }] =
    useFilePicker({
      readFilesContent: false,
      accept: [
        'image/bmp',
        'image/gif',
        'image/jpeg',
        'image/png',
        'image/tiff',
        'image/webp',
        '.pdf',
      ],
    });

  useEffect(() => {
    if (_.size(plainFiles) > 0) {
      onImageResult(plainFiles);
      clear();
    }
  }, [plainFiles, onImageResult, clear]);

  const errorText = useMemo(() => {
    if (_.isEmpty(errors)) {
      return '';
    }
    return 'Error when reading the file. Please try again.';
  }, [errors]);
  return (
    <>
      {loading ? (
        <ActivityIndicator size="large" />
      ) : (
        <>
          <ImageButton
            activeOpacity={0.8}
            last={true}
            onPress={openFileSelector}>
            <DashedSquare>
              <Text color="primary" size="medium">
                {addImageText}
              </Text>
            </DashedSquare>
          </ImageButton>
          {errorText && (
            <Text lh={18} color="error">
              {errorText}
            </Text>
          )}
        </>
      )}
    </>
  );
};

const DashedSquare = styled.View(({ theme }) => ({
  color: theme.colors.primary,
  width: theme.layout.verticalSpace(16),
  borderRadius: 5,
  paddingHorizontal: 8,
  paddingVertical: 10,
  borderColor: theme.colors.primary,
  borderWidth: 1,
  borderStyle: 'dashed',
  justifyContent: 'center',
  alignItems: 'center',
}));

type AddImageButtonProps = {
  onImageResult: Function,
};

export default AddImageButton;
