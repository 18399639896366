/**
 * @file
 * Reusable table cell components
 *
 * @format
 * @flow strict-local
 */

import React from 'react';
import _ from 'lodash';
import styled from '@emotion/native';

import { Cell, DataTable, PlainCell, Row } from '@appComponents/DataTable';
import Text, { PersonText } from '@appComponents/Text';
import { Box, MainSurface } from '@appComponents/ScreenLayout';
import UserRoleIndicator from '@appComponents/UserRoleIndicator';
import * as Phone from '@appUtils/phone';

export { Cell, PlainCell };

type TableProps = {
  list: Array,
  children: Node | Node[],
  loading?: boolean,
  error?: boolean | Object,
  listType?: string,
};

export const UsersTable = ({
  listType = 'users',
  list,
  children,
  loading,
  error,
  adjustHeader = true,
}: TableProps) => {
  let emptyMessage = <Text>No {listType} available</Text>;

  if (loading && _.isEmpty(list)) {
    emptyMessage = <Text>Loading...</Text>;
  }
  if (error) {
    emptyMessage = <Text>Failed to load {listType}. Try again later</Text>;
  }

  return (
    <MainSurface>
      <TableLayout
        data={list}
        emptyMessage={
          <Box height={200} ai="center" jc="center">
            {emptyMessage}
          </Box>
        }
        keyExtractor={(user, index) => user?.id ?? index}
        RowComponent={UserRow}
        adjustHeader={adjustHeader}>
        {children}
      </TableLayout>
    </MainSurface>
  );
};

const TableLayout: DataTable = styled(DataTable)(({ theme }) => ({
  flex: 1,
  marginBottom: theme.layout.space(0.5),
}));

const UserRow = styled(Row)(({ theme }) => ({
  backgroundColor: theme.colors.section,
}));

export const NameCell = ({ item, flex, roleIndicator = true }) => (
  <Cell flex={flex}>
    <Box dir="row" ai="center">
      {roleIndicator && <UserRoleIndicator user={item} />}
      <PersonText entry={item} />
    </Box>
  </Cell>
);

export const PhoneCell = ({ item, flex }) => (
  <Cell flex={flex}>
    {item.phoneNumber && Phone.parse(item.phoneNumber).formatNational()}
    {!item.phoneNumber && '-'}
  </Cell>
);
