import React from 'react';
import styled from '@emotion/native';

import { Box } from '@appComponents/ScreenLayout';
import Text from '@appComponents/Text';
import Icon from '@appComponents/theme/Icon';

const UserRoleLegend = () => (
  <Box>
    <Box dir="row">
      <Box dir="row" ai="center" pb={0.5} mr={1.5}>
        <RoleIcon name="client-indicator" size={15} />
        <Text size="small">Client</Text>
      </Box>
      <Box dir="row" ai="center" pb={0.5} mr={1.5}>
        <RoleIcon name="pilot-indicator" size={15} />
        <Text size="small">Pilot</Text>
      </Box>
      <Box dir="row" ai="center" pb={0.5} mr={1.5}>
        <RoleIcon name="manager-indicator" size={15} />
        <Text size="small">Manager</Text>
      </Box>
    </Box>
    <Box dir="row">
      <Box dir="row" ai="center" pb={0.5} mr={1.5}>
        <RoleIcon name="guest-passenger-indicator" size={15} />
        <Text size="small">Guest passenger</Text>
      </Box>
      <Box dir="row" ai="center" pb={0.5} mr={1.5}>
        <RoleIcon name="app-passenger-indicator" size={15} />
        <Text size="small">App passenger</Text>
      </Box>
    </Box>
  </Box>
);

const RoleIcon = styled(Icon)(({ theme }) => ({
  marginRight: theme.layout.space(0.25),
}));

export default UserRoleLegend;
