import React from 'react';

import { HelpBase, HelpText } from './Help';

const Faq = () => (
  <HelpBase>
    <HelpText bold>Why is a Pilot Unavailable?</HelpText>
    <ul>
      <li>
        <HelpText>
          When an assigned pilot shows up as "Unavailable" it's because they are
          assigned on another trip that overlaps with this trip.
        </HelpText>
      </li>
    </ul>
    <HelpText bold>Why is an Aircraft Unavailable?</HelpText>
    <ul>
      <li>
        <HelpText>
          An aircraft is shown as "Unavailable" either because it's on a trip
          that overlaps with this one or it has an Unflightworthy (UFW) squawk.
        </HelpText>
      </li>
    </ul>
    <HelpText bold>How does sorting trips by Pilot Status work?</HelpText>
    <ul>
      <li>
        <HelpText>
          When you sort by "Pilot Status" on the Trips page it sorts by the
          status of the Pilot in Command for that trip.
        </HelpText>
      </li>
    </ul>
    <HelpText bold>How are aircraft fuel values updated?</HelpText>
    <ul>
      <li>
        <HelpText>
          The current fuel shown in each aircraft is set in the aircraft section
          of the manager app and is also updated by pilots when they set fuel
          values on the latest trip for that aircraft.
        </HelpText>
      </li>
    </ul>
    <HelpText bold>Why can't I add expense receipt images?</HelpText>
    <ul>
      <li>
        <HelpText>
          When recording an expense in a trip an expense category is required,
          so you won't have the option of adding images to that record until you
          choose an expense category.
        </HelpText>
      </li>
    </ul>
    <HelpText bold>How do I add comments to an expense?</HelpText>
    <ul>
      <li>
        <HelpText>
          Expenses must be flagged or already have comments in order to add new
          comments. Flag the expense to enable comments.
        </HelpText>
      </li>
    </ul>
    <HelpText bold>Why can't I add comments to a new expense?</HelpText>
    <ul>
      <li>
        <HelpText>
          Comments can only be added to existing expenses, not new ones. Save
          the expense first, then you'll be able to add comments to it.
        </HelpText>
      </li>
    </ul>
    <HelpText bold>How do expense adjustment values work?</HelpText>
    <ul>
      <li>
        <HelpText>{`The total due is based on expenses and does not factor in adjustments.
The left column of the last row lists the remaining balance based on expenses and adjustments. That value can also be found in column D of the last row in the adjustments export CSV.
The left column of all rows, except the last row, reflects the remaining balance at the time that adjustment was made.
If an expense is added after making adjustments that amount will be reflected in the total due and the last row and but will not be reflected in the rows above it.`}</HelpText>
      </li>
    </ul>
    <HelpText bold>
      How do I create a Guest Passenger in the Manager App?
    </HelpText>
    <ol>
      <HelpText>
        There are 2 ways to create a Guest Passenger in the Manager App:
      </HelpText>
      <li>
        <HelpText>
          Guest Passengers can be added by navigating to the appropriate Client
          and clicking Add Guest Passenger.
        </HelpText>
      </li>
      <li>
        <HelpText>
          Guest Passengers can be added by typing a new name into the Add
          Passenger dropdown in the Trip Builder.
        </HelpText>
        <br />
        <HelpText>
          You can add weight, documents, and other details to this Guest
          Passenger by navigating to the appropriate Client.
        </HelpText>
      </li>
    </ol>
  </HelpBase>
);

export default Faq;
