import React from 'react';
import styled from '@emotion/native';

import { Box } from '@appComponents/ScreenLayout';
import Text from '@appComponents/Text';
import Icon from '@appComponents/theme/Icon';
import { UserRole } from '@appUtils/tripConverter';

const UserRoleIndicator = ({ user, size = 14 }) => {
  let iconName = 'guest-passenger-indicator';

  if (user?.id && user?.role) {
    switch (user.role) {
      case UserRole.OWNER:
        iconName = 'client-indicator';
        break;
      case UserRole.PILOT:
        iconName = 'pilot-indicator';
        break;
      case UserRole.MANAGER:
      case UserRole.EMPLOYEE:
        iconName = 'manager-indicator';
        break;
      case UserRole.PILOT:
      default:
        iconName = 'app-passenger-indicator';
    }
  }

  return <RoleIcon name={iconName} size={size} />;
};

const RoleIcon = styled(Icon)(({ theme }) => ({
  marginRight: theme.layout.space(0.25),
}));

export const LeadIndicator = () => (
  <LeadWrapper>
    <LeadText>Lead</LeadText>
  </LeadWrapper>
);

const LeadText = styled(Text)(({ theme }) => ({
  paddingHorizontal: 5,
  color: 'black',
}));

const LeadWrapper = styled(Box)(({ theme }) => ({
  borderRadius: theme.roundness,
  backgroundColor: theme.colors.indicator,
  marginLeft: theme.layout.gap(2),
}));

export default UserRoleIndicator;
